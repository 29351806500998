import { graphql } from './types';

export const OnlineOrderStateEventFragment = graphql(`
  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {
    id
    state
    timestamp
  }
`);

export const OnlineOrderProductsFragment = graphql(`
  fragment OnlineOrderProductsItem on OnlineOrderProduct {
    id
    title
    quantity
    discountable

    menuProduct {
      id
      images
      discountable
    }

    configurations {
      id
      title
      value
      price

      menuProductConfigurationId
      menuProductConfigurationValueId
    }
  }
`);

export const OnlineOrderFragment = graphql(`
  fragment OnlineOrderItem on OnlineOrder {
    id
    shortID

    userState
    confirmationState
    deliveryMode
    state

    estimatedDuration
    deliveryFeeCalculationStatus
    distanceToRestaurant
    longitude
    latitude

    streetAddress
    streetAddress2
    addressLocality
    addressRegion
    postalCode
    addressCountry

    expectedTime
    deleted

    guestName
    email
    phone
    message

    basePrice
    deliveryPrice
    deliveryDiscount
    pickupDiscount
    totalPrice

    events {
      ...OnlineOrderStateEventItem
    }

    onlineOrderProducts {
      ...OnlineOrderProductsItem
    }

    userCreatedAt
    createdAt
  }
`);

export const OnlineOrderListFragment = graphql(`
  fragment OnlineOrderListItem on OnlineOrder {
    id

    userState
    confirmationState
    deliveryMode
    state

    estimatedDuration
    deliveryFeeCalculationStatus
    distanceToRestaurant
    longitude
    latitude

    deleted

    streetAddress
    streetAddress2
    addressLocality
    addressRegion
    postalCode
    addressCountry

    expectedTime

    guestName
    email
    phone
    message

    basePrice
    deliveryPrice
    totalPrice

    events {
      ...OnlineOrderStateEventItem
    }

    userCreatedAt
    createdAt

    _count {
      onlineOrderProducts
    }
  }
`);
