import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
    query reservationGroupByGuest($branchId: ID!, $startTime: DateTime!, $endTime: DateTime!) {
        analytic_reservationsByGuestCount(
            branchId: $branchId
            startTime: $startTime
            endTime: $endTime
        ) {
            guestCount
            count
        }
    }
`);

export default function useReservationGroupByGuest(
  branchId: string | undefined,
  startTime: string,
  endTime: string,
) {
  return useQuery(QUERY, {
    variables: {
      branchId: branchId ?? '',
      startTime,
      endTime,
    },
    skip: !branchId,
  });
}
