import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query LoggerQuantity($companyId: ID!, $loggerFilter: LoggerFilter) {
    loggerQuantity(companyId: $companyId, loggerFilter: $loggerFilter)
  }
`);

export default function useLoggerQuantity(companyId: string, loggerFilter?: { startTime: string; endTime: string }) {
  return useQuery(QUERY, {
    variables: {
      companyId,
      loggerFilter,
    },
  });
}
