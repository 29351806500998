import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import { Helmet } from 'react-helmet-async';
import CompanyBasicSettings from '@client/page/management/company-settings/company-basic-settings.tsx';
import CompanyAdvancedSettings from '@client/page/management/company-settings/company-advanced-settings.tsx';
import CompanySocialSetting from '@client/page/management/company-settings/company-social-setting.tsx';
import EmailManager from '@client/page/management/company-settings/company-extra-email-setting.tsx';
import useCheckAdminRole from '@client/module/auth/check-role-hook.ts';
import CompanyImprintSetting from '@client/page/management/company-settings/company-imprint-setting.tsx';

export default function CompanySettings() {
  const { isAdmin } = useCheckAdminRole();
  return (
    <ContentLayout>
      <Helmet>
        <title>Setting</title>
        <meta name="description" content="This page displays a list of company settings" />
      </Helmet>
      <PrimaryContent>
        <CompanyBasicSettings />
        <CompanySocialSetting />
        {isAdmin && <EmailManager />}
        <CompanyImprintSetting />
        <CompanyAdvancedSettings />
      </PrimaryContent>
    </ContentLayout>
  );
}
