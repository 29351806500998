import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation DeleteBusinessData($branchId: ID!, $startTime: String!, $endTime: String!, $services: [Features!]!) {
    deleteBusinessData(branchId: $branchId, startTime: $startTime, endTime: $endTime, services: $services)
  }
`);

export default function useDeleteBusinessData() {
  return useMutation(MUTATION, {});
}
