import Card from '@packages/ui/card/card.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import { useCallback } from 'react';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import useFormData from '@client/module/utils/use-form-data.ts';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '@tolgee/react';
import { DaysOfWeek, MenuSectionTimeRuleType } from '@client/graphql/types/graphql';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import CheckBoxInput from 'ui/src/form/input/check-box-input';
import classNames from 'classnames';
import SelectDaysOfWeek from '@client/page/management/menu/menu-section-create/component/select-days-of-week.tsx';
import { useCreateServiceMenuSection } from '@client/page/management/appointment/service/service-section/logic/use-create-service-menu-section.ts';

interface FormData {
  name: string;
  description: string;
  enabled: boolean;
  timeRuleType: MenuSectionTimeRuleType;
  start: string;
  end: string;
  daysOfWeek: DaysOfWeek[];
  isAllDays: boolean;
}

const menuSectionTypeList = [
  { key: 'Always', value: MenuSectionTimeRuleType.Always },
  { key: 'Range time', value: MenuSectionTimeRuleType.RangeTime },
];

export default function ServiceSectionCreate() {
  const { t } = useTranslate();
  const serviceId = useParams().serviceId;
  const navigate = useNavigate();

  const [create, { loading, error }] = useCreateServiceMenuSection();
  const { data, handle, manualHandle } = useFormData<FormData>({
    name: '',
    description: '',
    enabled: false,
    daysOfWeek: [],
    isAllDays: false,
    timeRuleType: MenuSectionTimeRuleType.Always,
    start: '00:00',
    end: '23:59',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = useCallback(() => {
    create({
      variables: {
        menuId: serviceId ?? '',
        input: {
          name: data.name,
          description: data.description,
          enabled: true,
        },
        timeRuleInput:
          data.timeRuleType == MenuSectionTimeRuleType.Always
            ? {
                type: data.timeRuleType,
              }
            : {
                type: data.timeRuleType,
                start: data.start,
                end: data.end,
                daysOfWeek: data.daysOfWeek,
                isAllDays: data.isAllDays,
              },
      },
    })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [
    create,
    serviceId,
    data.name,
    data.description,
    // data.enabled,
    data.timeRuleType,
    data.start,
    data.end,
    data.daysOfWeek,
    data.isAllDays,
    navigate,
  ]);

  return (
    <Card>
      <Helmet>
        <title>{t('service.service-detail.category.create.helmet.title')}</title>
        <meta name="description" content="This page allows you to add a new menu section" />
      </Helmet>
      <CardHeader title={t('service.service-detail.category.create.header', 'Add category')} withBackButton={true} />

      <CardContent>
        <FormLayout>
          <FormItem
            className="max-w-md"
            title={t('service.service-detail.category.create.form-input.name.title', 'Category name')}
          >
            <TextInput
              data-testid="name-input"
              type="text"
              label="name"
              name="name"
              placeholder={t(
                'service.service-detail.category.create.form-input.name.placeholder',
                'Your category name'
              )}
              value={data.name}
              error={validationError.name}
              autoComplete={'off'}
              onChange={handle}
            />
          </FormItem>

          <FormItem
            className="max-w-md"
            title={t('service.service-detail.category.create.form-input.description.title', 'Description')}
          >
            <TextInput
              data-testid="description-input"
              type="text"
              label="description"
              name="description"
              placeholder={t(
                'service.service-detail.category.create.form-input.description.placeholder',
                'Your categoty description'
              )}
              value={data.description}
              error={validationError.description}
              autoComplete={'off'}
              onChange={handle}
            />
          </FormItem>
          <FormItem
            title={t('service.service-detail.category.create.form-input.time-rule.title', 'Time rule type')}
            className="max-w-md"
          >
            <div className="flex flex-col gap-4">
              <MenuSelectionInput
                title=""
                data={menuSectionTypeList}
                value={menuSectionTypeList.find((item) => item.value == data.timeRuleType) ?? menuSectionTypeList[0]}
                onChange={(newValue) => {
                  manualHandle('timeRuleType', newValue?.value);
                }}
                build={(item) => {
                  return {
                    id: item?.value ?? '',
                    name: item?.key ?? '',
                  };
                }}
                className="-my-2 w-full"
              />
            </div>
            {data.timeRuleType == MenuSectionTimeRuleType.RangeTime && (
              <>
                <div className="mt-8">
                  <label className="block flex items-center gap-1.5 text-sm font-medium leading-6 text-gray-900">
                    <span>
                      {t('service.service-detail.category.create.form-input.time-rule.range-time.title', 'Range')}
                    </span>
                  </label>
                  <FormItem title={''} className={classNames('relative', data.isAllDays ? 'opacity-45' : '')}>
                    <div className="max-w-full flex gap-2">
                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="Start Date"
                        id="start"
                        name="start"
                        value={data.start}
                        type="time"
                        onChange={handle}
                      />

                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="End Date"
                        id="end"
                        name="end"
                        value={data.end}
                        type="time"
                        onChange={handle}
                      />
                    </div>

                    {data.isAllDays && <div className="absolute inset-0"></div>}
                  </FormItem>
                </div>

                <FormItem className="" title={''}>
                  <div className="flex items-center">
                    <CheckBoxInput
                      value={data.isAllDays}
                      onChange={function (_, value: boolean): void {
                        manualHandle('isAllDays', value);
                      }}
                    />
                    <span className="text-xs italic text-gray-600">
                      {t(
                        'service.service-detail.category.create.form-input.time-rule.apply-all',
                        'Apply for all time on current day opening time'
                      )}
                    </span>
                  </div>
                </FormItem>
                <div>
                  {data.start > data.end && !data.isAllDays && (
                    <p className="mt-2 text-xs italic text-red-600" id="email-error">
                      {t(
                        'service.service-detail.category.create.form-input.time-rule.alert',
                        'Choose startDate and endDate, startDate must be smaller than endDate'
                      )}
                    </p>
                  )}
                </div>

                <FormItem
                  className="mt-8"
                  title={t('service.service-detail.category.create.form-input.time-rule.dow.title', 'Day of week')}
                >
                  <SelectDaysOfWeek
                    value={data.daysOfWeek}
                    onChange={(value: DaysOfWeek[]) => {
                      manualHandle('daysOfWeek', value);
                    }}
                  />
                </FormItem>
              </>
            )}

            {data.timeRuleType == MenuSectionTimeRuleType.Always && <div></div>}
          </FormItem>

          {/*<FormItem title="Active" className="w-32">*/}
          {/*  <ToggleInput*/}
          {/*    value={data.enabled}*/}
          {/*    onChange={(newValue) => {*/}
          {/*      manualHandle('enabled', newValue);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</FormItem>*/}
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-96">
          <SecondaryButton
            className="flex-1"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('common.button.back', 'Back')}
          </SecondaryButton>
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              onSubmit();
            }}
            loading={loading}
          >
            {t('service.service-detail.category.create.button.create', 'Create')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
