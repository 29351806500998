import { useCallback, useEffect, useState } from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { client } from '@client/graphql/client.ts';
import { graphql } from '@client/graphql/types';

export interface PushNotificationService {
  currentToken: string | undefined;

  enable(): Promise<void> | undefined;
}

const UPDATE_PUSH_TOKEN = graphql(`
  mutation UpdatePushToken($token: String!) {
    pushNotificationRegisterToken(token: $token)
  }
`);

export function usePushNotificationServiceProvider(): PushNotificationService {
  const [currentToken, setCurrentToken] = useState<string>();

  const notificationCenter = useNotificationCenter();
  const authContext = useAuth();

  // Callbacks
  const requestPermission = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      return true;
    } else {
      notificationCenter.showAlert({
        title: 'Notification permission denied',
        message: 'You will not receive notifications.',
        status: 'error',
      });

      return false;
    }
  }, [notificationCenter]);

  // Callbacks
  const retrieveTokenAndSendToBackend = useCallback(async () => {
    const messaging = getMessaging();

    const currentToken = await getToken(messaging, {
      vapidKey: import.meta.env.VITE_FIREBASE_WEB_PUSH_KEY as string,
    });

    await client.mutate({
      mutation: UPDATE_PUSH_TOKEN,
      variables: { token: currentToken },
    });

    setCurrentToken(currentToken);
  }, []);

  const enable = useCallback(() => {
    if (import.meta.env.PROD || import.meta.env.VITE_FORCE_ENABLE_FIREBASE === 'true') {
      return requestPermission()
        .then(() => {
          return retrieveTokenAndSendToBackend();
        })
        .then(() => {
          notificationCenter.showAlert({
            title: 'Push notification enabled',
            message: 'You will now receive push notifications.',
            status: 'success',
          });
        });
    }
  }, [notificationCenter, requestPermission, retrieveTokenAndSendToBackend]);

  // Side effect
  useEffect(() => {
    const isProduction = import.meta.env.PROD || import.meta.env.VITE_FORCE_ENABLE_FIREBASE === 'true';

    if (isProduction && authContext.auth != null) {
      retrieveTokenAndSendToBackend().catch(() => void 0);
    }
  }, [authContext.auth, retrieveTokenAndSendToBackend]);

  return {
    currentToken,
    enable,
  };
}
