import { TableReservationConfirmationStatus, TableReservationItemFragment } from '@client/graphql/types/graphql.ts';
import { useTranslate } from '@tolgee/react';

interface StatItem {
  name: string;
  value: string | number;
  unit?: string;
}

const TableReservationListStats = (props: { loading?: boolean; items: TableReservationItemFragment[] }) => {
  const { items } = props;

  const stats: StatItem[] = [
    {
      name: 'Bookings',
      value: items.filter((item) => item.confirmationStatus === TableReservationConfirmationStatus.Confirmed).length,
    },
    {
      name: 'Guests',
      value: items
        .filter((item) => item.confirmationStatus === TableReservationConfirmationStatus.Confirmed)
        .reduce((prev, next) => {
          return prev + next.seats;
        }, 0),
    },
    {
      name: 'Waiting',
      value: items.filter((item) => item.confirmationStatus === TableReservationConfirmationStatus.Waiting).length,
    },

    {
      name: 'Cancelled',
      value: items.filter((item) => item.confirmationStatus === TableReservationConfirmationStatus.Cancelled).length,
    },
  ];
  const { t } = useTranslate();

  return (
    <div className="px-6 py-2 flex flex-col space-y-0">
      <div className="text-lg text-gray-700">
        <span className="">
          {stats[0]?.value} {t('reservation.schedule.stats.booking')} / {stats[1]?.value}{' '}
          {t('reservation.schedule.stats.guests')}
        </span>
      </div>
      <div>
        <span className="text-sm text-gray-500">
          {stats[2]?.value} {t('reservation.schedule.stats.waiting')} / {stats[3]?.value}{' '}
          {t('reservation.schedule.stats.cancellation')}
        </span>
      </div>
    </div>
  );
};

export default TableReservationListStats;
