import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import ReservationDailyTrendsAnalytics
  from '@client/page/management/table-reservation/analytic-new/components/reservation-daily-trends-analytics.tsx';
import ReservationHourlyTrendsAnalytics
  from '@client/page/management/table-reservation/analytic-new/components/reservation-hourly-trends-analytics.tsx';
import ReservationGroupByGuest
  from '@client/page/management/table-reservation/analytic-new/components/reservation-group-by-guest.tsx';
import ReservationGroupByConfirmationStatus
  from '@client/page/management/table-reservation/analytic-new/components/reservation-group-by-confirmation-status.tsx';
import {
  TableReservationRangeFilter,
} from '@client/page/management/table-reservation/list/component/table-reservation-range-filter.tsx';
import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

export default function TableReservationAnalytic() {
  const [startTime, setStartTime] = useState<Dayjs>(dayjs().subtract(1, 'month'));
  const [endTime, setEndTime] = useState<Dayjs>(dayjs());

  const quickActions: Record<string, () => void> = {
    'Last 30 days': () => {
      setStartTime(dayjs().subtract(1, 'month'));
      setEndTime(dayjs());
    },
    'Last 3 months': () => {
      setStartTime(dayjs().subtract(3, 'month'));
      setEndTime(dayjs());
    },
    'Last 6 months': () => {
      setStartTime(dayjs().subtract(6, 'month'));
      setEndTime(dayjs());
    },
    'Last year': () => {
      setStartTime(dayjs().subtract(1, 'year'));
      setEndTime(dayjs());
    },
  };

  return (
    <ContentLayout>
      <FullPrimaryContent>
        <div className="flex flex-wrap gap-y-2 gap-x-4">
          {
            Object.keys(quickActions).map((action) => (
              <SecondaryButton key={action} onClick={quickActions[action]}>
                {action}
              </SecondaryButton>
            ))
          }

          <TableReservationRangeFilter
            startTime={startTime}
            endTime={endTime}
            onChange={(newValues) => {
              setStartTime(newValues[0]);
              setEndTime(newValues[1]);
            }}
          />
        </div>

        <ReservationDailyTrendsAnalytics startTime={startTime} endTime={endTime} />
        <ReservationHourlyTrendsAnalytics startTime={startTime} endTime={endTime} />
        <div className="flex space-x-4">
          <div className="flex-grow">
            <ReservationGroupByGuest startTime={startTime} endTime={endTime} />
          </div>
          <div className="basis-1/4">
            <ReservationGroupByConfirmationStatus startTime={startTime} endTime={endTime} />
          </div>
        </div>
      </FullPrimaryContent>
    </ContentLayout>
  );
}
