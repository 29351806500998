import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ChartOptions, type ChartData } from 'chart.js';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useReservationHourlyTrends
  from '@client/page/management/table-reservation/analytic-new/logic/use-reservation-hourly-trends.ts';
import { Dayjs } from 'dayjs';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';

export default function ReservationHourlyTrendsAnalytics(props: {
  startTime: Dayjs;
  endTime: Dayjs;
}) {
  const { branch } = useFocusedBranchContext();

  const { data } = useReservationHourlyTrends(
    branch?.id,
    props.startTime.format(),
    props.endTime.format(),
  );

  const [chartData, setChartData] = useState<ChartData<'line'>>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!data) {
      return;
    }

    // One day
    const labels = data.daily.map((item) =>
      item.hour.toString(),
    );

    // Total reservations
    const totalReservations = data.daily.reduce(
      (acc, item) => acc + item.count,
      0,
    );

    const everydayTrends = data.daily.map(
      (item) => (item.count / totalReservations) * 100,
    );

    setChartData({
      labels: labels,
      datasets: [
        {
          label: '1 day',
          data: everydayTrends,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          cubicInterpolationMode: 'monotone',
        },
      ],
    });
  }, [data, setChartData]);

  const options: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            if (Number.isInteger(value)) {
              return `${value.toString()}%`; //
            }
            return value; // Append '%' to y-axis labels
          },
        },
        title: {
          display: true,
          text: 'Percentage',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
    },
  };

  return (
    <Card className="">
      <CardHeader title="Pick hours" />
      <CardContent>
        <Line options={options} data={chartData} />
      </CardContent>
    </Card>
  );
}
