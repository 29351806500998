import useConfirmOnlineOrder from '@client/page/management/online-order/detail/logic/use-confirm-online-order.ts';
import { useCallback } from 'react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useTranslate } from '@tolgee/react';

const OnlineOrderActionConfirm = (props: { orderID: string; preparingTime?: number | null; onClose?: () => void }) => {
  const { orderID, preparingTime, onClose } = props;

  const { showAlert } = useNotificationCenter();

  const [confirm, { loading }] = useConfirmOnlineOrder();
  const { t } = useTranslate();

  const confirmHandler = useCallback(() => {
    if (preparingTime == null) {
      showAlert({
        status: 'error',
        title: t('alert.title.error', 'Error'),
        message: t(
          'online-order.confirm.alert.preparing-time-null.message',
          'You have not set preparing time, please select again.'
        ),
      });
      return;
    }
    confirm({
      variables: {
        id: orderID,
        input: {
          preparingTime: preparingTime,
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.detail.action.confirm.alert.message'),
        });
        onClose?.();
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: e.message,
        });
      });
  }, [confirm, orderID, preparingTime, showAlert, onClose, t]);

  return (
    <PrimaryButton onClick={confirmHandler} disabled={loading}>
      {t('online-order.detail.action.confirm.button')}
    </PrimaryButton>
  );
};

export default OnlineOrderActionConfirm;
