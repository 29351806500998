import Table from 'ui/src/table/table';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import { TableCellAlignment } from 'ui/src/table/table-type';
import { OnlineOrderDelivery } from '@client/page/management/online-order/setting/model/online-order-delivery.model.ts';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from '@tolgee/react';
import { OnlineOrderDeliveryTableConfigurationInput } from '@packages/network-graphql/graphql/types/graphql.ts';

export default function DeliveryTable(props: {
  deliveryDataList: OnlineOrderDelivery[];
  showUpdateModal: (deliveryId: string) => void;
  autoDeliveryConfirm: boolean;
}) {
  const { deliveryDataList, showUpdateModal } = props;
  const { t } = useTranslate();

  return (
    <Table
      data={deliveryDataList}
      build={(deliveryData, index) => (
        <DeliveryDataRow
          autoDeliveryConfirm={props.autoDeliveryConfirm}
          key={index}
          deliveryData={deliveryData}
          showUpdateModal={showUpdateModal}
        />
      )}
    >
      <TableHeaderColumn alignment={TableCellAlignment.Center}>
        {t('online-order.setting-page.edit.delivery-setting.table.minimum-price', 'Minimum price')}
      </TableHeaderColumn>
      <TableHeaderColumn alignment={TableCellAlignment.Center}>
        {t('online-order.setting-page.edit.delivery-setting.table.delivery-distance')}
      </TableHeaderColumn>
      {props.autoDeliveryConfirm && (
        <TableHeaderColumn alignment={TableCellAlignment.Center}>
          {t('online-order.setting-page.edit.delivery-setting.table.delivery-time')}
        </TableHeaderColumn>
      )}
      <TableHeaderColumn alignment={TableCellAlignment.Center}>
        {t('online-order.setting-page.edit.delivery-setting.table.delivery-fee')}
      </TableHeaderColumn>
    </Table>
  );
}

function DeliveryDataRow(props: {
  deliveryData: OnlineOrderDeliveryTableConfigurationInput;
  showUpdateModal: (deliveryId: string) => void;
  autoDeliveryConfirm: boolean;
}) {
  const { deliveryData, showUpdateModal } = props;
  return (
    <TableRow
      onClick={() => {
        showUpdateModal(deliveryData.id);
      }}
    >
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <CurrencyView className="font-semibold" price={deliveryData.minimumOrderBasePrice} />
      </TableRowColumn>
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <p className="font-medium">
          &lt;
          {(deliveryData.deliveryDistance / 1000).toFixed(2)} km
        </p>
      </TableRowColumn>
      {props.autoDeliveryConfirm && (
        <TableRowColumn alignment={TableCellAlignment.Center}>
          <p className="font-medium">{deliveryData.deliveryTime} minutes</p>
        </TableRowColumn>
      )}
      <TableRowColumn alignment={TableCellAlignment.Center}>
        <CurrencyView className="font-semibold" price={deliveryData.deliveryFee} />
      </TableRowColumn>
    </TableRow>
  );
}
