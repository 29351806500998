import { useParams } from 'react-router-dom';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderFragment, OnlineOrderProductsFragment } from '@client/graphql/online-order-fragment';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import DescriptionItem from 'ui/src/description/description-item';
import Loading from 'ui/src/loading';
import { OnlineOrderDeliveryMode, OnlineOrderItemFragment } from '@client/graphql/types/graphql';
import { capitalize } from 'lodash';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import OnlineProductTable from '@client/page/management/online-order/detail/component/online-product-table.tsx';
import DescriptionSection from '@packages/ui/description/description-section.tsx';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { OnlineOrderBranchSettingFragment } from '@client/graphql/online-order-branch-setting-fragment.ts';
import OnlineOrderAction from '@client/page/management/online-order/detail/component/online-order-action.tsx';
import { MapPreview } from '@client/page/management/online-order/detail/map-preview.tsx';
import { useTranslate } from '@tolgee/react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/16/solid';
import { PrinterIcon } from '@heroicons/react/24/outline';
import usePrintToKitchen from '@client/page/management/online-order/detail/logic/use-print-to-kitchen.ts';
// import { captureException } from '@sentry/react';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import useGetOnlineOrder from './logic/use-get-online-order';

export default function OnlineOrderDetail() {
  // Params
  const onlineOrderID = useParams().onlineOrderID ?? '';
  // const previousPath = useContext(CustomLocationContext) ?? '';

  const branchProvider = useFocusedBranchContext();
  const companyProvider = useFocusedCompanyContext();

  const branchID = branchProvider.branch?.id ?? '';

  const { data, loading, error } = useGetOnlineOrder(onlineOrderID, branchID);

  const [printToKitchen] = usePrintToKitchen();

  const onlineOrderData = getFragmentData(OnlineOrderFragment, data?.onlineOrder);
  const onlineOrderProducts = getFragmentData(OnlineOrderProductsFragment, onlineOrderData?.onlineOrderProducts);
  const configuration = getFragmentData(OnlineOrderBranchSettingFragment, data?.onlineOrderBranchConfiguration);
  const { t } = useTranslate();
  if (loading) {
    return <Loading />;
  }

  if (!onlineOrderData || !onlineOrderProducts || !configuration) {
    return <>No data</>;
  }
  //
  // const backTarget = previousPath.includes('history')
  //   ? `..?nearestDetailTime=${dayjs(onlineOrderData.createdAt).toISOString()}`
  //   : -1;

  return (
    <>
      <ContentLayout>
        <PrimaryContent>
          <ApplicationErrorView error={error} />
          <OnlineOrderAction order={onlineOrderData} compact={true} />
          <Card>
            <CardHeader title={t('online-order.detail.order.title')} />
            <CardTableContent>
              <OnlineProductTable order={onlineOrderData} configuration={configuration} />
            </CardTableContent>
          </Card>
          <Card>
            <CardHeader title={t('online-order.detail.title')}>
              <Menu>
                <MenuButton
                  className="flex items-center gap-2 shadow-xs rounded-md h-8 px-3 font-semibold shadow-white/10
          focus:outline-none text-xl"
                >
                  <EllipsisHorizontalIcon className="w-6 h-6 text-gray-700" />
                </MenuButton>

                <MenuItems
                  transition
                  anchor="bottom start"
                  className="border border-gray-200 rounded-xl bg-white shadow-md [--anchor-gap:8px] [--anchor-padding:8px]"
                >
                  <MenuItem>
                    <button
                      onClick={() => {
                        void printToKitchen({ variables: { id: onlineOrderID } });
                      }}
                      className="flex w-full items-center gap-2 rounded-lg px-3 py-3 hover:bg-gray-100"
                    >
                      <PrinterIcon className="w-6 h-6" />
                      Print to kitchen
                      <kbd className="ml-auto hidden font-sans text-xs text-white/50 group-data-[focus]:inline">⌘E</kbd>
                    </button>
                  </MenuItem>
                </MenuItems>
              </Menu>
            </CardHeader>
            <CardContent>
              <FormLayout>
                <DescriptionItem title={t('common.form-input.ID')}>{onlineOrderData.shortID}</DescriptionItem>

                <DescriptionItem title={t('common.form-input.status')}>
                  {capitalize(onlineOrderData.state.replace(/_/g, ' '))}
                </DescriptionItem>
                <div className="grid md:grid-cols-2 gap-y-4">
                  <DescriptionItem title={t('common.form-input.create-time', 'Created time')}>
                    {moment(onlineOrderData.userCreatedAt || onlineOrderData.createdAt).tz(companyProvider.settings.timezone).format('HH:mm')}
                  </DescriptionItem>

                  {onlineOrderData.expectedTime && (
                    <DescriptionItem title={t('online-order.dashboard.order-status.dialog.order-time', 'Order time')}>
                      {moment(onlineOrderData.expectedTime).tz(companyProvider.settings.timezone).format('HH:mm')}
                    </DescriptionItem>
                  )}
                </div>

                <div className="grid md:grid-cols-2">
                  <DescriptionItem title={t('common.form-input.guest-name')}>
                    {onlineOrderData.guestName}
                  </DescriptionItem>

                  <DescriptionItem
                    title={t('common.form-input.email')}
                    action={[
                      {
                        href: `mailto:${onlineOrderData.email ?? ''}`,
                        name: t('online-order.detail.email.action.send-email', 'Send Email'),
                      },
                    ]}
                  >
                    {onlineOrderData.email}
                  </DescriptionItem>

                  <DescriptionItem
                    title={t('common.form-input.phone')}
                    action={[
                      {
                        href: `sms:${onlineOrderData.phone ?? ''}`,
                        name: t('online-order.detail.phone.action.send-sms', 'Send SMS'),
                      },
                      {
                        href: `tel:${onlineOrderData.phone ?? ''}`,
                        name: t('online-order.detail.phone.action.call', 'Call now'),
                      },
                    ]}
                  >
                    {onlineOrderData.phone}
                  </DescriptionItem>

                  {(onlineOrderData.message?.length ?? 0) > 0 && (
                    <DescriptionItem title={t('common.form-input.message')}>{onlineOrderData.message}</DescriptionItem>
                  )}
                </div>

                <DescriptionSection title={t('online-order.detail.delivery.title')}>
                  <DescriptionItem title={t('online-order.detail.delivery.information')}>
                    {t('online-order.detail.delivery.type.' + capitalize(onlineOrderData.deliveryMode) + '.title')}
                  </DescriptionItem>

                  {onlineOrderData.deliveryMode == OnlineOrderDeliveryMode.Delivery && (
                    <>
                      <DescriptionItem title={t('online-order.detail.delivery.type.Delivery.location')}>
                        {formatAddress(onlineOrderData)}
                      </DescriptionItem>
                      <DescriptionItem title={t('online-order.detail.delivery.type.Delivery.distance')}>
                        {((onlineOrderData.distanceToRestaurant ?? 0) / 1000).toFixed(2)} kmm
                      </DescriptionItem>
                    </>
                  )}

                  {onlineOrderData.latitude && onlineOrderData.longitude && (
                    <MapPreview latitude={onlineOrderData.latitude} longitude={onlineOrderData.longitude} />
                  )}
                </DescriptionSection>
              </FormLayout>
            </CardContent>
          </Card>
        </PrimaryContent>
      </ContentLayout>
    </>
  );
}

const formatAddress = (onlineOrder: OnlineOrderItemFragment) => {
  const components = [
    onlineOrder.streetAddress,
    onlineOrder.streetAddress2,
    onlineOrder.addressLocality,
    `${onlineOrder.postalCode ?? ''} ${onlineOrder.addressRegion ?? ''}`,
  ].filter((component) => (component?.length ?? 0) > 0);

  return components.join(', ');
};
