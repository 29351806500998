import React from 'react';
import Center from '@packages/ui/center.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import classNames from 'classnames';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { useTranslate } from '@tolgee/react';

/**
 * Table component
 *
 * @example
 *  <Table
 *     data={data?.menuProductConfigurations ?? []}
 *     build={(item) => {
 *       return <TableSection colSpan={4}/>
 *
 *       return <TableRow key={value.id}>
 *          <TableRowColumn/>
 *       </TableRow>
 *    }}
 *     loading={loading}
 *     error={error?.message}
 *    >
 *    <TableHeaderColumn>Name</TableHeaderColumn>
 *    <TableHeaderColumn alignment={TableCellAlignment.center}>Actions</TableHeaderColumn>
 *  </Table>
 */
export default function Table<T>(props: {
  children: React.ReactNode;
  data: readonly T[];
  build: (data: T, index: number) => React.ReactNode;
  footer?: React.ReactNode;
  loading?: boolean;
  error?: string | undefined;
  rounded?: boolean;
  refetch?: () => void;
}) {
  const { t } = useTranslate();

  return (
    <div className={classNames('ring-1 ring-gray-200 sm:mx-0 overflow-visible')}>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr className="h-12">{props.children}</tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {!props.loading && !props.error && (
              <>
                {props.data.map(props.build)}
                {props.footer}
              </>
            )}
          </tbody>
        </table>
      </div>

      {props.error && (
        <AlertError title={t('alert-error.title', 'Error')} message={props.error}>
          <SecondaryButton
            onClick={() => {
              props.refetch && props.refetch();
            }}
            className="mt-2"
          >
            {t('alert-error.button.reload', 'Reload')}
          </SecondaryButton>
        </AlertError>
      )}

      {props.loading && (
        <Center className="p-4">
          <Spinner />
        </Center>
      )}
    </div>
  );
}
