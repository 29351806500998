import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { ChartOptions, type ChartData } from 'chart.js';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useReservationDailyTrends
  from '@client/page/management/table-reservation/analytic-new/logic/use-reservation-daily-trends.ts';
import {
  useFocusedBranchContext,
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { Dayjs } from 'dayjs';

export default function ReservationDailyTrendsAnalytics(props: {
  startTime: Dayjs;
  endTime: Dayjs;
}) {
  const { branch } = useFocusedBranchContext();

  const { data } = useReservationDailyTrends(
    branch?.id,
    props.startTime.format(),
    props.endTime.format(),
  );

  const [chartData, setChartData] = useState<ChartData<'line'>>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!data) {
      return;
    }

    // One day
    const labels = data.daily.totalReservationPerDate.map((item) =>
      moment(item.date).format('MMM DD'),
    );
    const everydayTrends = data.daily.totalReservationPerDate.map(
      (item) => item.totalReservations,
    );

    const weeklyTrends = data.weekly.totalReservationPerDate.map(
      (item) => item.totalReservations,
    );

    const monthlyTrends = data.monthly.totalReservationPerDate.map(
      (item) => item.totalReservations,
    );

    setChartData({
      labels: labels,
      datasets: [
        {
          label: '1 day',
          data: everydayTrends,
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.5)',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: '7 days',
          data: weeklyTrends,
          borderColor: 'rgb(255, 159, 64)',
          backgroundColor: 'rgba(255, 159, 64, 0.5)',
          cubicInterpolationMode: 'monotone',
        },
        {
          label: '30 days',
          data: monthlyTrends,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          cubicInterpolationMode: 'monotone',
        },
      ],
    });
  }, [data, setChartData]);

  const options: ChartOptions<'line'> = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Reservations',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Date',
        },
      },
    },
  };

  return (
    <Card className="">
      <CardHeader title="Reservation Trends" />
      <CardContent>
        <Line options={options} data={chartData} />
      </CardContent>
    </Card>
  );
}
