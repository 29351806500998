import { Link } from 'react-router-dom';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import Table from '@packages/ui/table/table.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { FragmentType, getFragmentData } from '@client/graphql/types';
import {
  groupMenuProductItemsBySection,
  ProductMenuBySection,
} from '@client/page/management/menu/menu-detail/logic/group-menu-product-item-by-section.ts';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { MenuProductFragment } from '@packages/network-graphql/common/menu-product-fragment.ts';
import { MenuSectionFragment } from '@packages/network-graphql/common/menu-section-fragment.ts';
import {
  MenuProductConfigurationValueFragment,
} from '@packages/network-graphql/common/menu-product-configuration-value-fragment.ts';
import { useTranslate } from '@tolgee/react';
import { naturalCompare } from '@client/module/utils/natural-compare.ts';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PercentBadgeIcon } from '@heroicons/react/20/solid';

export default function MenuProductTable(props: {
  products: FragmentType<typeof MenuProductFragment>[];
  sections: FragmentType<typeof MenuSectionFragment>[];
}) {
  const data = getFragmentData(MenuProductFragment, props.products);
  const sections = getFragmentData(MenuSectionFragment, props.sections);
  const sortedProductMenuBySections = groupMenuProductItemsBySection(data, sections).sort((a, b) =>
    naturalCompare(a.section?.code ?? '', b.section?.code ?? ''),
  );
  const { t } = useTranslate();
  return (
    <div className="overflow-scroll">
      <Table
        data={sortedProductMenuBySections}
        build={(group) => <Group key={group.section?.id ?? 'no-section'} group={group} />}
      >
        <TableHeaderColumn className="w-5">{t('common.table-header-column.code')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.product')}</TableHeaderColumn>
        <TableHeaderColumn alignment={TableCellAlignment.Right}>
          {t('common.table-header-column.price')}
        </TableHeaderColumn>
      </Table>
    </div>
  );
}

function Group(props: { group: ProductMenuBySection }) {
  const { group } = props;
  const currency = useCompanyCurrency();
  const { t } = useTranslate();

  return (
    <>
      <TableSection key={group.section?.id} colSpan={4}>
        <p className="flex justify-center">
          {group.section && (
            <Link className=" flex items-center justify-between w-full" to={`sections/${group.section.id}/edit`}>
              <div className="flex-1 flex justify-center space-x-2">
                {group.section.code.length > 0 ? (
                  <>
                    <p>{group.section.code}</p>
                    {group.section.name.length > 0 && <p>-</p>}
                  </>
                ) : (
                  <p>{t('menu.menu-detail.table-product.no-position')}</p>
                )}
                <p>{group.section.name.length > 0 ? group.section.name : 'No name'}</p>
                <PencilIcon className="w-4 h-4" />
              </div>
              <div className="flex-0 flex justify-end">
                {!group.section.discountable && <PercentBadgeIcon className="w-5 h-5 fill-red-300" />}
              </div>
            </Link>
          )}
        </p>
      </TableSection>

      {group.items
      .sort((a, b) => naturalCompare(a.code, b.code))
      .map((menuProductItem) => {
        // const displayDiscountIcon = group.section && !group.section.discountable;

        return (
          <TableRow key={menuProductItem.id}>
            <TableRowColumn>
              <Link to={`products/${menuProductItem.id}`}>
                <p className="font-semibold">{menuProductItem.code}</p>
              </Link>
            </TableRowColumn>
            <TableRowColumn>
              <Link to={`products/${menuProductItem.id}`}>
                <p className="whitespace-normal">{menuProductItem.title}</p>
              </Link>
            </TableRowColumn>
            <TableRowColumn alignment={TableCellAlignment.Right}>
              <Link to={`products/${menuProductItem.id}/configurations`}>
                <div className="flex space-x-2 justify-end items-end">
                  {menuProductItem.configurations.some((config) => config.values.length > 1) && (
                    <p className="text-gray-400">Start at</p>
                  )}
                  <p className="text-gray-500 flex gap-1">
                    {formatCurrency(
                      menuProductItem.configurations
                      .find((config) => config.type.includes('BASE'))
                      ?.values.map((configValue) =>
                        getFragmentData(MenuProductConfigurationValueFragment, configValue),
                      )
                      .at(0)?.price ?? 0,
                      currency,
                    )}
                  </p>

                  {!((group.section?.discountable ?? true) && menuProductItem.discountable) && (
                    <PercentBadgeIcon className="w-5 h-5 fill-red-300" />
                  )}
                </div>
              </Link>
            </TableRowColumn>
          </TableRow>
        );
      })}
    </>
  );
}
