import Card from '@packages/ui/card/card.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import { useCallback } from 'react';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useNavigate, useParams } from 'react-router-dom';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import {
  useCreateMenuSection,
} from '@client/page/management/menu/menu-section-create/logic/use-create-menu-section.ts';
import useFormData from '@client/module/utils/use-form-data.ts';
import { Helmet } from 'react-helmet-async';
import { useTranslate } from '@tolgee/react';
import { DaysOfWeek, MenuSectionTimeRuleType } from '@client/graphql/types/graphql';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import CheckBoxInput from 'ui/src/form/input/check-box-input';
import classNames from 'classnames';
import SelectDaysOfWeek from './component/select-days-of-week';

interface FormData {
  name: string;
  description: string;
  code: string;
  enabled: boolean;
  discountable: boolean;
  timeRuleType: MenuSectionTimeRuleType;
  start: string;
  end: string;
  daysOfWeek: DaysOfWeek[];
  isAllDays: boolean;
}

const menuSectionTypeList = [
  { key: 'Always', value: MenuSectionTimeRuleType.Always },
  { key: 'Range time', value: MenuSectionTimeRuleType.RangeTime },
];

export default function MenuSectionCreate() {
  const { t } = useTranslate();
  const menuId = useParams().menuId ?? 'default';
  const navigate = useNavigate();

  const [create, { loading, error }] = useCreateMenuSection();
  const { data, handle, manualHandle } = useFormData<FormData>({
    name: '',
    description: '',
    code: '',
    enabled: false,
    discountable: false,
    daysOfWeek: [],
    isAllDays: false,
    timeRuleType: MenuSectionTimeRuleType.Always,
    start: '00:00',
    end: '23:59',
  });

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const onSubmit = useCallback(() => {
    create({
      variables: {
        menuId: menuId,
        input: {
          discountable: !data.discountable,
          name: data.name,
          description: data.description,
          code: data.code,
          enabled: true,
        },
        timeRuleInput:
          data.timeRuleType == MenuSectionTimeRuleType.Always
            ? {
              type: data.timeRuleType,
            }
            : {
              type: data.timeRuleType,
              start: data.start,
              end: data.end,
              daysOfWeek: data.daysOfWeek,
              isAllDays: data.isAllDays,
            },
      },
    })
      .then(() => {
        navigate('..');
      })
      .catch((e) => void e);
  }, [
    create,
    menuId,
    data.name,
    data.description,
    data.code,
    data.discountable,
    // data.enabled,
    data.timeRuleType,
    data.start,
    data.end,
    data.daysOfWeek,
    data.isAllDays,
    navigate,
  ]);

  return (
    <Card>
      <Helmet>
        <title>{t('menu.menu-detail.category.create.helmet.title')}</title>
        <meta name="description" content="This page allows you to add a new menu section" />
      </Helmet>
      <CardHeader title={t('menu.menu-detail.category.create.header')} />

      <CardContent>
        <FormLayout>
          <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.position.title')}>
            <TextInput
              data-testid="name-input"
              type="text"
              label="code"
              name="code"
              placeholder={t('menu.menu-detail.category.create.form-input.position.placeholder')}
              value={data.code}
              error={validationError.code}
              autoComplete={'off'}
              onChange={handle}
            />
          </FormItem>

          <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.name.title')}>
            <TextInput
              data-testid="name-input"
              type="text"
              label="name"
              name="name"
              placeholder={t('menu.menu-detail.category.create.form-input.name.placeholder')}
              value={data.name}
              error={validationError.name}
              autoComplete={'off'}
              onChange={handle}
            />
          </FormItem>

          <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.description.title')}>
            <TextInput
              data-testid="description-input"
              type="text"
              label="description"
              name="description"
              placeholder={t('menu.menu-detail.category.create.form-input.description.placeholder')}
              value={data.description}
              error={validationError.description}
              autoComplete={'off'}
              onChange={handle}
            />
          </FormItem>

          <FormItem className="max-w-md" title={t('menu.menu-detail.category.create.form-input.discountable.title','Additional options')}>
            <CheckBoxInput
              value={data.discountable}
              onChange={(_, value: boolean) => {
                manualHandle('discountable', value);
              }}
              name={t('menu.menu-detail.category.create.form-input.discountable.checkbox','No Discountable')}
            />
          </FormItem>
          <FormItem title={'Time rule type'} className="max-w-md">
            <div className="flex flex-col gap-4">
              <MenuSelectionInput
                title=""
                data={menuSectionTypeList}
                value={menuSectionTypeList.find((item) => item.value == data.timeRuleType) ?? menuSectionTypeList.at(0)}
                onChange={(newValue) => {
                  manualHandle('timeRuleType', newValue?.value);
                }}
                build={(item) => {
                  return {
                    id: item?.value ?? '',
                    name: item?.key ?? '',
                  };
                }}
                className="-my-2 w-full"
              />
            </div>
            {data.timeRuleType == MenuSectionTimeRuleType.RangeTime && (
              <>
                <div className="mt-8">
                  <label className="block flex items-center gap-1.5 text-sm font-medium leading-6 text-gray-900">
                    <span>Range</span>
                  </label>
                  <FormItem title={''} className={classNames('relative', data.isAllDays ? 'opacity-45' : '')}>
                    <div className="max-w-full flex gap-2">
                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="Start Date"
                        id="start"
                        name="start"
                        value={data.start}
                        type="time"
                        onChange={handle}
                      />

                      <input
                        className="rounded-md max-w-full border-gray-300"
                        aria-label="End Date"
                        id="end"
                        name="end"
                        value={data.end}
                        type="time"
                        onChange={handle}
                      />
                    </div>

                    {data.isAllDays && <div className="absolute inset-0"></div>}
                  </FormItem>
                </div>

                <FormItem className="" title={''}>
                  <div className="flex items-center">
                    <CheckBoxInput
                      value={data.isAllDays}
                      onChange={(_, value: boolean) => {
                        manualHandle('isAllDays', value);
                      }}
                    />
                    <span className="text-xs italic text-gray-600">Apply for all time on current day opening time</span>
                  </div>
                </FormItem>
                <div>
                  {data.start > data.end && !data.isAllDays && (
                    <p className="mt-2 text-xs italic text-red-600" id="email-error">
                      {'Choose startDate and endDate, startDate must be smaller than endDate'}
                    </p>
                  )}
                </div>

                <FormItem className="mt-8" title="Days of week">
                  <SelectDaysOfWeek
                    value={data.daysOfWeek}
                    onChange={(value: DaysOfWeek[]) => {
                      manualHandle('daysOfWeek', value);
                    }}
                  />
                </FormItem>
              </>
            )}

            {data.timeRuleType == MenuSectionTimeRuleType.Always && <div></div>}
          </FormItem>

          {/*<FormItem title="Active" className="w-32">*/}
          {/*  <ToggleInput*/}
          {/*    value={data.enabled}*/}
          {/*    onChange={(newValue) => {*/}
          {/*      manualHandle('enabled', newValue);*/}
          {/*    }}*/}
          {/*  />*/}
          {/*</FormItem>*/}

          {applicationErrors?.map((e, index) => {
            return <AlertError key={index} title={e.domain} message={e.message} />;
          })}
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-96">
          <SecondaryButton
            className="flex-1"
            onClick={() => {
              navigate(-1);
            }}
          >
            {t('common.button.back')}
          </SecondaryButton>
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              onSubmit();
            }}
            loading={loading}
          >
            {t('menu.menu-detail.category.create.button.create', 'Create')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
