import { useNavigate, useParams } from 'react-router-dom';
import useFormData from '@client/module/utils/use-form-data.ts';
import { useGetBranch } from '@client/page/management/branch/branch-edit/logic/use-get-branch.tsx';
import { useUpdateBranch } from '@client/page/management/branch/branch-edit/logic/use-update-branch.tsx';
import { useFetchBranchCoordinate } from '@client/page/management/branch/branch-edit/logic/use-fetch-branch-coordinate.ts';
import { useCallback, useEffect } from 'react';
import Center from '@packages/ui/center.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { MapPreview } from '@client/page/management/online-order/detail/map-preview.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import BranchDeleteButton from '@client/page/management/branch/branch-edit/components/delete-branch-button.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useTranslate } from '@tolgee/react';

interface BranchEditForm {
  name: string;
  website: string;

  contactPhone: string;
  contactEmail: string;

  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;

  latitude: string;
  longitude: string;
}

export default function BranchEdit(props: { focusBranch: boolean }) {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const branchId = useParams().branchId ?? '';
  const { showAlert } = useNotificationCenter();

  const {
    data: form,
    setData,
    handle,
  } = useFormData<BranchEditForm>({
    name: '',
    website: '',
    streetAddress: '',
    addressLocality: '',
    addressRegion: '',
    postalCode: '',
    addressCountry: '',
    latitude: '',
    longitude: '',
    contactPhone: '',
    contactEmail: '',
  });

  const { loading, error: loadingError, data, refetch } = useGetBranch(branchId);
  const [updateBranch, { error: updatingError, loading: updating }] = useUpdateBranch();
  const [fetchBranchCoordinate, { error: fetchBranchError, loading: fetchBranchLoading }] = useFetchBranchCoordinate();

  const submit = useCallback(() => {
    updateBranch({
      variables: {
        id: branchId,
        input: {
          ...form,
          website: form.website.trim(),
          latitude: parseFloat(form.latitude),
          longitude: parseFloat(form.longitude),
        },
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          title: 'Success',
          message: 'Branch has been updated',
        });
      })
      .catch((e: Error) => {
        showAlert({
          status: 'error',
          title: 'Error',
          message: e.message,
        });
      });
  }, [branchId, form, showAlert, updateBranch]);

  const fetchBranchCoordinateHandler = useCallback(() => {
    // TODO: Use notification center
    fetchBranchCoordinate({
      variables: {
        branchID: branchId,
      },
    })
      .then(() => {
        return refetch();
      })
      .catch((e) => void e);
  }, [fetchBranchCoordinate, branchId, refetch]);

  useEffect(() => {
    setData({
      name: data?.branch.name ?? '',
      website: data?.branch.website ?? '',
      streetAddress: data?.branch.streetAddress ?? '',
      addressLocality: data?.branch.addressLocality ?? '',
      addressRegion: data?.branch.addressRegion ?? '',
      postalCode: data?.branch.postalCode ?? '',
      addressCountry: data?.branch.addressCountry ?? '',
      latitude: data?.branch.latitude?.toString() ?? '',
      longitude: data?.branch.longitude?.toString() ?? '',
      contactPhone: data?.branch.contactPhone ?? '',
      contactEmail: data?.branch.contactEmail ?? '',
    });
  }, [data, setData]);

  const error = loadingError ?? updatingError ?? fetchBranchError;
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  if (loading) {
    return (
      <Center className="h-32">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Card>
        <CardHeader title={t('branch-detail.header', 'Edit Branch')} withBackButton={!props.focusBranch} />

        <CardContent>
          <FormLayout>
            <FormItem className="max-w-md" title={t('branch-detail.form-input.name', 'Name')} obligatory={true}>
              <TextInput
                type="text"
                name="name"
                label="name"
                autoComplete="off"
                placeholder="Name"
                value={form.name}
                error={validationError.name}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.website', 'Website')}>
              <TextInput
                type="text"
                name="website"
                label="website"
                autoComplete="off"
                placeholder="Website"
                value={form.website}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.contact-phone', 'Contact Phone')}>
              <TextInput
                type="number"
                name="contactPhone"
                label="contactPhone"
                autoComplete="off"
                placeholder="Contact Phone"
                value={form.contactPhone}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title="Contact Email">
              <TextInput
                type="email"
                name="contactEmail"
                label="contactEmail"
                autoComplete="off"
                placeholder="Contact Email"
                value={form.contactEmail}
                onChange={handle}
              />
            </FormItem>

            <FormItem
              className="max-w-md"
              title={t('branch-detail.form-input.postal-code', 'Postal Code')}
              obligatory={true}
            >
              <TextInput
                type="text"
                name="postalCode"
                label="postalCode"
                autoComplete="off"
                placeholder="Postal Code"
                value={form.postalCode}
                error={validationError.postalCode}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.city', 'City')} obligatory={true}>
              <TextInput
                type="text"
                name="addressLocality"
                label="addressLocality"
                autoComplete="off"
                placeholder="Locality"
                value={form.addressLocality}
                error={validationError.addressLocality}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.address', 'Address')} obligatory={true}>
              <TextInput
                type="text"
                name="streetAddress"
                label="streetAddress"
                autoComplete="off"
                placeholder="Street"
                value={form.streetAddress}
                error={validationError.streetAddress}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.region', 'Region')}>
              <TextInput
                type="text"
                name="addressRegion"
                label="addressRegion"
                autoComplete="off"
                placeholder="Region"
                value={form.addressRegion}
                onChange={handle}
              />
            </FormItem>

            <FormItem className="max-w-md" title={t('branch-detail.form-input.country', 'Country')} obligatory={true}>
              <TextInput
                type="text"
                name="addressCountry"
                label="addressCountry"
                autoComplete="off"
                placeholder="Country"
                value={form.addressCountry}
                error={validationError.addressCountry}
                onChange={handle}
              />
            </FormItem>

            <div className="flex items-end gap-4">
              <FormItem className="max-w-44" title={t('branch-detail.form-input.latitude', 'Latitude')}>
                <TextInput
                  type="text"
                  name="latitude"
                  label="latitude"
                  autoComplete="off"
                  placeholder="Latitude"
                  value={form.latitude}
                  onChange={handle}
                />
              </FormItem>

              <FormItem className="max-w-44" title={t('branch-detail.form-input.longitude', 'Longitude')}>
                <TextInput
                  type="text"
                  name="longitude"
                  label="longitude"
                  autoComplete="off"
                  placeholder="Longitude"
                  value={form.longitude}
                  onChange={handle}
                />
              </FormItem>

              <SecondaryButton onClick={fetchBranchCoordinateHandler} disabled={updating || fetchBranchLoading}>
                {t('branch-detail.form-input.fetch-button', 'Fetch')}
              </SecondaryButton>
            </div>

            {form.latitude && form.longitude && (
              <MapPreview latitude={parseFloat(form.latitude)} longitude={parseFloat(form.longitude)} />
            )}
          </FormLayout>

          {error && <AlertError className="mt-4" title={'Error'} message={error.message} />}
        </CardContent>

        <CardFooter>
          <div className="flex items-center justify-center gap-x-4">
            {props.focusBranch || (
              <SecondaryButton
                className="w-32"
                onClick={() => {
                  navigate('..');
                }}
              >
                {t('common.cancel', 'Cancel')}
              </SecondaryButton>
            )}
            <PrimaryButton
              className="w-32"
              onClick={submit}
              disabled={updating || fetchBranchLoading}
              loading={updating || fetchBranchLoading}
            >
              {t('common.save', 'Save')}
            </PrimaryButton>
          </div>
        </CardFooter>
      </Card>

      {props.focusBranch || (
        <Card>
          <CardContent>
            <FormLayout>
              <FormItem title={'Branch ID'}>
                <p className="pb-4">{branchId}</p>
              </FormItem>
              <FormItem title={''}>
                <BranchDeleteButton menuId={branchId} />
              </FormItem>
            </FormLayout>
          </CardContent>
        </Card>
      )}
    </>
  );
}
