import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import { User } from '@client/graphql/types/graphql';
import { Link } from 'react-router-dom';
import { useTranslate } from '@tolgee/react';

interface UserTableRowData {
  user: User;
  permissionCount: number;
}

export default function UserTable(props: { loading?: boolean; items: UserTableRowData[] | undefined }) {
  const { items } = props;
  const { t } = useTranslate();

  if (!items) {
    return <div>Empty data</div>;
  }

  return (
    <>
      <Table
        loading={props.loading}
        data={items}
        build={(item) => <TableGroupRow key={item.user.id} user={item.user} permissionCount={item.permissionCount} />}
      >
        <TableHeaderColumn>{t('user-list.table.header-column.username', 'User  name')}</TableHeaderColumn>
        <TableHeaderColumn>{t('user-list.table.header-column.fullname', 'Full Name')}</TableHeaderColumn>
        <TableHeaderColumn
          className="hidden sm:table-cell">{t('user-list.table.header-column.email', 'Email')}</TableHeaderColumn>
        <TableHeaderColumn
          className="hidden xl:table-cell">{t('user-list.table.header-column.status', 'Status')}</TableHeaderColumn>
        <TableHeaderColumn
          className="hidden xl:table-cell">{t('user-list.table.header-column.permission', 'Permissions')}</TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: { user: User; permissionCount: number }) {
  const { user } = props;
  const { t } = useTranslate();

  return (
    <>
      <TableRow key={user.id} className="hover:bg-gray-100">
        <TableRowColumn>
          <Link to={user.id}>
            <p className="w-full">{user.username}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn>
          <Link to={user.id}>
            <p className="w-full">{user.firstName + ' ' + user.lastName}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn className="hidden sm:table-cell">
          <Link to={user.id}>
            <p className="w-full max-w-32 truncate">{user.email}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn className="hidden xl:table-cell">
          <Link to={user.id}>
            <p
              className="w-full">{user.status ? t('user-list.table.row-column.status.active', 'Active') :
              t('user-list.table.row-column.status.non-active', 'Non active')}</p>
          </Link>
        </TableRowColumn>
        <TableRowColumn className="hidden xl:table-cell">
          <Link to={user.id}>
            <p className="w-full">{props.permissionCount}</p>
          </Link>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
