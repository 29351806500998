import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { useCallback, useState } from 'react';
import FormLayout from 'ui/src/form/form-layout';
import FormItem from 'ui/src/form/form-item';
import CardHeader from '@packages/ui/card/card-header';
import SecondaryButton from '@packages/ui/button/secondary-button';
import PrimaryButton from '@packages/ui/button/primary-button';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { OpeningTimeServiceType } from '@client/graphql/types/graphql.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useCopyOpeningTime } from '@client/page/management/opening-time/opening-time-copy/logic/use-copy-opening-time.ts';
import { useTranslate } from '@tolgee/react';

export default function OpeningTimeCopy(props: { onClose: () => void; targetService: OpeningTimeServiceType }) {
  const { onClose, targetService } = props;

  const services = Object.values(OpeningTimeServiceType);

  const filteredServices = services.filter((service) => service !== targetService);

  const [sourceService, setSourceService] = useState(OpeningTimeServiceType.Location);

  const { branch } = useFocusedBranchContext();

  const [copy, { error, loading }] = useCopyOpeningTime(branch?.id ?? '', sourceService, targetService);

  const submit = useCallback(() => {
    copy()
      .then(() => {
        onClose();
      })
      .catch((e) => void e);
  }, [copy, onClose]);

  const { t } = useTranslate();

  return (
    <>
      <Card>
        <CardHeader title={t('reservation.setting.copy-opening-time.title')} withBackButton={true} />
        <CardContent>
          <FormLayout>
            <FormItem className="max-w-md capitalize" title={t('reservation.setting.copy-opening-time.source-service')}>
              <div className="space-y-2">
                {filteredServices.map((service) => (
                  <div key={service} className="flex items-center">
                    <input
                      id={`radio-${service}`}
                      name="source-service"
                      type="radio"
                      checked={sourceService === service}
                      onChange={() => {
                        setSourceService(service);
                      }}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={`radio-${service}`}
                      className="ml-3 block text-sm font-medium text-gray-700 capitalize"
                    >
                      {service.replaceAll('_', ' ').toLowerCase()}
                    </label>
                  </div>
                ))}
              </div>
            </FormItem>
          </FormLayout>

          {error && <AlertError className="mt-4" title={'Error'} message={error.message} />}
        </CardContent>

        <div
          className="bg-gray-50 px-2 py-3 flex justify-end rounded-b-lg space-x-2
         md:px-4 md:space-x-4 md:py-5 sm:px-6"
        >
          <SecondaryButton className="w-32" onClick={onClose}>
            {t('common.button.cancel')}
          </SecondaryButton>
          <PrimaryButton id="copy-button" data-testid="copy-button" className="w-32" onClick={submit} loading={loading}>
            {t('common.button.copy')}
          </PrimaryButton>
        </div>
      </Card>
    </>
  );
}
