import Table from '@packages/ui/table/table';
import TableHeaderColumn from '@packages/ui/table/table-header-column';
import TableRow from '@packages/ui/table/table-row';
import TableRowColumn from '@packages/ui/table/table-row-cell';
import { ActionType, Entity } from '@client/graphql/types/graphql';
import { useTranslate } from '@tolgee/react';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import moment from 'moment-timezone';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';

interface UserActivity {
  id: string;
  user: {
    username: string;
  };
  action: ActionType;
  details: unknown;
  timestamp: string;
  entity: Entity;
  entityId: string;
}

export default function LoggingTable(props: { items: UserActivity[] | undefined }) {
  const { items } = props;

  const company = useFocusedCompany();
  const { t } = useTranslate();
  const dialogManager = useModalManagerContext();

  if (!items) {
    return <div>Empty data</div>;
  }

  const detailButtonHandleClick = (detail: unknown) => {
    dialogManager.showDialog((onClose) => {
      const formattedJson = JSON.stringify(detail, null, 2); // Pretty-print JSON with 2 spaces indentation
      return (
        <Card>
          <CardHeader title={t('user-activity.detail.header', 'Logger detail')} onCrossButton={onClose} />
          <CardContent>
            <pre>{formattedJson}</pre>
          </CardContent>
        </Card>
      );
    });
  };
  return (
    <>
      <Table
        data={items}
        build={(item) => (
          <TableGroupRow
            key={item.id}
            item={item}
            timezone={company.settings.timezone}
            detailButtonHandleClick={detailButtonHandleClick}
          />
        )}
      >
        <TableHeaderColumn>{t('user-activity-list.table.header-column.time', 'Time')}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('user-activity-list.table.header-column.userName', 'Username')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('user-activity-list.table.header-column.action', 'Action')}
        </TableHeaderColumn>
        {/*Mobile*/}
        <TableHeaderColumn className="sm:hidden max-w-32">
          {t('user-activity-list.table.header-column.username-action', 'Username / action')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden xl:table-cell">
          {t('user-activity-list.table.header-column.entity', 'Entity / Entity Id')}
        </TableHeaderColumn>
        <TableHeaderColumn className="">
          {t('user-activity-list.table.header-column.entity', 'Detail')}
        </TableHeaderColumn>
      </Table>
    </>
  );
}

function TableGroupRow(props: {
  item: UserActivity;
  timezone: string;
  detailButtonHandleClick: (detail: unknown) => void;
}) {
  const { item, timezone, detailButtonHandleClick } = props;
  const { t } = useTranslate();
  return (
    <>
      <TableRow key={item.id} className="hover:bg-gray-100">
        <TableRowColumn>
          <p className="w-full">{moment.tz(item.timestamp, timezone).format('YYYY-MM-DD HH:mm')}</p>
        </TableRowColumn>
        <TableRowColumn className="hidden sm:table-cell">
          <p className="w-full">{item.user.username}</p>
        </TableRowColumn>
        <TableRowColumn className="hidden sm:table-cell">
          <p className="w-full max-w-32 truncate">{item.action.toLowerCase()}</p>
        </TableRowColumn>
        {/*Mobile*/}
        <TableHeaderColumn className="sm:hidden">
          <p className="w-full">{item.user.username}</p>
          <p className="w-full max-w-32 truncate font-light text-gray-500">{item.action.toLowerCase()}</p>
          <p className="w-full max-w-32 truncate font-light text-gray-500">
            {item.entity.split('_').join(' ').toLowerCase()}
          </p>
        </TableHeaderColumn>
        <TableRowColumn className="hidden xl:table-cell">
          <p className="w-full font-semibold">{item.entity.split('_').join(' ').toLowerCase()}</p>
          <p className="w-full italic">{item.entityId}</p>
        </TableRowColumn>
        <TableRowColumn className="">
          <SecondaryButton
            onClick={() => {
              detailButtonHandleClick(item.details);
            }}
          >
            {t('detail-button', 'details')}
          </SecondaryButton>
        </TableRowColumn>
      </TableRow>
    </>
  );
}
