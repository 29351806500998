import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query Loggers($companyId: ID!, $offset: Int!, $limit: Int!, $loggerFilter: LoggerFilter) {
    loggers(companyId: $companyId, offset: $offset, limit: $limit, loggerFilter: $loggerFilter) {
      id
      user {
        username
      }
      action
      details
      timestamp
      entity
      entityId
    }
  }
`);

export default function useLoggers(
  companyId: string,
  offset: number,
  limit: number,
  loggerFilter?: { startTime: string; endTime: string }
) {
  return useQuery(QUERY, {
    variables: {
      companyId,
      offset,
      limit,
      loggerFilter,
    },
  });
}
