import { NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import useUpdateAppointment from '@client/page/management/appointment/detail/logic/use-update-appointment.ts';
import { Controller, useForm } from 'react-hook-form';
import {
  PickedProductInput,
  UpdateAppointmentInput,
} from '@client/page/management/appointment/create/util/appointment-create-model.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { useParams } from 'react-router-dom';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import ServiceSelection from '@client/page/management/appointment/create/component/service-selection.tsx';
import moment from 'moment-timezone';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Card from '@packages/ui/card/card.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import PhoneInput from 'react-phone-number-input/min';
import { getFragmentData } from '@client/graphql/types';
import { PickedNailAppointmentEmployeeFragment } from '@packages/network-graphql/common/appointment-picked-team.ts';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

export default function UpdateAppointment(props: {
  appointmentData: NailAppointmentItemFragment;
  onClose: () => void;
}) {
  const { appointmentData, onClose } = props;
  const appointmentId = useParams().appointmentId ?? '';
  const [update] = useUpdateAppointment();

  const { showAlert } = useNotificationCenter();
  const company = useFocusedCompany();

  const { t } = useTranslate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<UpdateAppointmentInput>({
    defaultValues: {
      name: appointmentData.customerVariant.name,
      email: appointmentData.customerVariant.email,
      phone: appointmentData.customerVariant.phone,
      message: appointmentData.message ?? '',
      startTime:
        moment(appointmentData.startTime).tz(company.settings.timezone).format('YYYY-MM-DD HH:mm') ||
        moment().tz(company.settings.timezone).format('YYYY-MM-DD HH:mm'),
      pickerProduct: convertToPickerProduct(appointmentData),
    },
  });

  const onSubmit = (data: UpdateAppointmentInput) => {
    update({
      variables: {
        id: appointmentId,
        input: {
          startTime: data.startTime,
          message: data.message,
          name: data.name,
          email: data.email,
          phone: data.phone,
        },
        pickedProductInput: data.pickerProduct,
        userId: appointmentData.employees[0]
          ? getFragmentData(PickedNailAppointmentEmployeeFragment, appointmentData.employees[0]).user.userId
          : '',
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          message: t('appointment.update.alert.success.message', 'Your appointment has been created successfully.'),
          title: t('appointment.update.alert.success.title', 'Success'),
        });
        onClose();
      })
      .catch((err: Error) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);
          for (const field in validationError) {
            setError(
              field as keyof UpdateAppointmentInput,
              {
                type: 'server',
                message: validationError[field],
              },
              { shouldFocus: true }
            );
          }
        }
        showAlert({
          status: 'error',
          message: err.message,
          title: 'Error',
        });
      });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardHeader title={t('appointment.update.header', 'Update appointment')} />

        <CardContent>
          <FormLayout>
            <FormItem title={t('appointment.update.name.title', 'Name')} className="max-w-xl">
              <Controller
                control={control}
                // rules={{
                //   required: t('appointment.update.name.validate.require', 'Name is required'),
                //   minLength: {
                //     value: 3,
                //     message: t('appointment.update.name.validate.min-length', 'Min length is 3'),
                //   },
                //   maxLength: {
                //     value: 50,
                //     message: t('appointment.update.name.validate.max-length', 'Max length is 50'),
                //   },
                // }}
                defaultValue={''}
                name="name"
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    label="name"
                    name="name"
                    onChange={onChange}
                    placeholder=""
                    type="text"
                    error={errors.name?.message}
                    value={value}
                  />
                )}
              />
            </FormItem>

            <div title="" className="max-w-xl grid grid-cols-1 xl:grid-cols-2 gap-x-2 gap-y-8">
              <FormItem title={t('appointment.update.email.title', 'Email')} className="max-w-xl">
                <Controller
                  control={control}
                  defaultValue={''}
                  // rules={{
                  //   required: t('appointment.update.email.validate.require', 'Business email is required'),
                  //   pattern: {
                  //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  //     message: 'Invalid email address, example:email@gmail.com',
                  //   },
                  // }}
                  name="email"
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      label="email"
                      name="email"
                      onChange={onChange}
                      placeholder="example@gmail.com"
                      type="text"
                      error={errors.email?.message}
                      value={value}
                    />
                  )}
                />
              </FormItem>

              <FormItem title={t('appointment.update.phone.title', 'Phone')} className="max-w-xl">
                <Controller
                  control={control}
                  defaultValue={''}
                  name="phone"
                  // rules={{
                  //   required: t('appointment.update.phone.validate.require', 'Phone is required'),
                  //   pattern: {
                  //     value: /^\d+$/,
                  //     message: t('appointment.update.phone.validate.only-number', 'Phone can only be numbers'),
                  //   },
                  // }}
                  render={({ field: { onChange, value } }) => (
                    <PhoneInput
                      className="pl-2.5 pt-1.5 pb-1.5 pr-2 h-auto block w-full rounded-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={onChange}
                      value={value}
                      defaultCountry={'DE'}
                      placeholder={t('common.form-input.phone')}
                    />
                  )}
                />
              </FormItem>
            </div>

            <Controller
              control={control}
              name="pickerProduct"
              // rules={{
              //   required: t('appointment.update.picker-product.validate.require', 'Picker product is required'),
              // }}
              render={({ field: { onChange, value } }) => (
                <>
                  <FormItem title={t('appointment.update.service.title', 'Service')} className="max-w-xl">
                    <ServiceSelection
                      services={value}
                      setService={(pickedProduct: PickedProductInput) => {
                        onChange([pickedProduct]);
                      }}
                    />

                    {errors.pickerProduct && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.pickerProduct.message}
                      </p>
                    )}
                  </FormItem>
                </>
              )}
            />

            <FormItem title={t('appointment.update.message.title', 'Message')} className="max-w-xl">
              <Controller
                control={control}
                defaultValue={''}
                name="message"
                render={({ field: { onChange, value } }) => (
                  <textarea
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 xl:min-h-24
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                    name="message"
                    onChange={onChange}
                    placeholder="Message"
                    value={value}
                  />
                )}
              />
            </FormItem>

            <FormItem title={t('appointment.update.booking-time.title', 'Booking time')}>
              <Controller
                control={control}
                defaultValue={''}
                name="startTime"
                // rules={{
                //   required: t('appointment.update.start-time.validate.require', 'Start time is required'),
                // }}
                render={({ field: { onChange, value } }) => (
                  <>
                    <input
                      className="rounded-md max-w-full border-gray-300"
                      aria-label="Start Date"
                      id="start"
                      name="start"
                      value={value}
                      type="datetime-local"
                      onChange={onChange}
                    />

                    {errors.startTime && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.startTime.message}
                      </p>
                    )}
                  </>
                )}
              />
            </FormItem>
          </FormLayout>
        </CardContent>

        <CardFooter>
          <SecondaryButton
            onClick={() => {
              onClose();
            }}
          >
            {t('common.button.cancel', 'Cancel')}
          </SecondaryButton>
          <PrimaryButton>
            <input className="" type="submit" value={t('common.button.update', 'Update')} />
          </PrimaryButton>
        </CardFooter>
      </Card>
    </form>
  );
}

function convertToPickerProduct(appointment: NailAppointmentItemFragment) {
  const products = appointment.products;

  if (!products[0]?.pickedProduct.menuProduct.configurations[0]?.values[0]) {
    return [];
  }

  return products[0]
    ? [
        {
          productID: products[0].pickedProduct.menuProduct.id,
          pickedConfiguration: [
            {
              configurationID: products[0].pickedProduct.menuProduct.configurations[0].id,
              valueID: products[0].pickedProduct.menuProduct.configurations[0].values[0].id,
            },
          ],
        },
      ]
    : [];
}
