import { Dayjs } from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useNavigate } from 'react-router-dom';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNailAppointments } from '@client/page/management/appointment/list/logic/use-appointments.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import NailAppointmentListStats from '@client/page/management/appointment/list/component/nail-appointment-list-stats.tsx';
import NailAppointmentTableList from '@client/page/management/appointment/list/component/nail-appointment-table-list.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import { NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';

const AppointmentList = (props: {
  withTitle?: boolean;
  startTime: Dayjs;
  endTime: Dayjs;
  header?: ReactNode;
  footer?: ReactNode;
  navigationPrefix?: string;
}) => {
  const { startTime, endTime, header, footer } = props;

  //hook
  const navigate = useNavigate();
  const { t } = useTranslate();

  //param
  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  //fetch
  const {
    data: appointmentData,
    loading,
    error,
    refetch,
  } = useNailAppointments(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });

  const [appointments, setAppointments] = useState<NailAppointmentItemFragment[]>([]);

  useEffect(() => {
    setAppointments(appointmentData.filter((item) => !item.deleted));
  }, [appointmentData, setAppointments]);

  return (
    <Card>
      <CardHeader title={t('appointment.schedule.title', 'Appointments')}>{header}</CardHeader>
      <CardTableContent>
        <NailAppointmentListStats items={appointments} loading={loading} />
        <NailAppointmentTableList
          loading={loading}
          error={error?.message}
          refetch={() => {
            void refetch();
          }}
          items={appointments}
          onClick={(id) => {
            if (props.navigationPrefix) {
              navigate(`${props.navigationPrefix}/${id}`);
            } else {
              navigate(id);
            }
          }}
        />
      </CardTableContent>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};
export default AppointmentList;
