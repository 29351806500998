import moment from 'moment-timezone';

export default function validateTime(startTime: string, endTime: string) {
  const errors: Record<string, string> = {};

  const start = moment(startTime);
  const end = moment(endTime);

  if (start.isAfter(end, 'days')) {
    errors.date = 'holiday.form-input.date';
  }

  if (start.isSame(end, 'days') || start.isBefore(end, 'days')) {
    if (start.format('HH:mm') > end.format('HH:mm')) {
      errors.hour = 'holiday.form-input.hour';
    }
  }

  return errors;
}
