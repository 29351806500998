import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
    query reservationConfirmationStatusRate($branchId: ID!, $startTime: DateTime!, $endTime: DateTime!) {
        analytic_reservationsByConfirmationStatus(
            branchId: $branchId
            startTime: $startTime
            endTime: $endTime
        ) {
            status
            count
        }
    }
`);

export default function useReservationConfirmationStatusRate(
  branchId: string | undefined,
  startTime: string,
  endTime: string,
) {
  return useQuery(QUERY, {
    variables: {
      branchId: branchId ?? '',
      startTime,
      endTime,
    },
    skip: !branchId,
  });
}
