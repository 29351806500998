import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { NailAppointmentConfirmationStatus, NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment, ReactElement } from 'react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { AppointmentTableListConfirmationStatus } from '@client/page/management/appointment/list/component/appointment-table-list-confirmation-status.tsx';
import classNames from 'classnames';
import VirtualUserSelectionDepend from '@client/page/management/appointment/list/component/virtual-user-selection-depend.tsx';
import { getFragmentData } from '@client/graphql/types';
import { PickedNailAppointmentEmployeeFragment } from '@packages/network-graphql/common/appointment-picked-team.ts';
import EmployeeDot from '@client/page/management/appointment/employee/component/employee-dot.tsx';

export default function NailAppointmentTableList(props: {
  loading?: boolean;
  error?: string;
  items: NailAppointmentItemFragment[];
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: (id: string) => void;
  refetch: () => void;
}) {
  const { items, actions } = props;
  const { t } = useTranslate();
  const company = useFocusedCompany();

  if (!props.loading && items.length === 0) {
    return <div className="px-6 py-4 border-t">{t('appointment.no-appointment')}</div>;
  }

  // Group by date
  const timezone = company.settings.timezone;
  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, appoinments]) => {
    const sortedAppointments = Array.from(appoinments);
    sortedAppointments.sort((a, b) => {
      return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      appointments: sortedAppointments,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    if (!a.appointments[0] || !b.appointments[0]) {
      return -1;
    }
    return moment(a.appointments[0].startTime).isAfter(moment(b.appointments[0].startTime)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        error={props.error}
        refetch={props.refetch}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-xl text-indigo-600">{item.date}</span>
              </div>
            </TableSection>

            {item.appointments.map((appointment) => (
              <AppointmentRow
                key={appointment.id}
                item={appointment}
                actions={actions}
                onClick={() => props.onClick?.(appointment.id)}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        {/*Desktop*/}
        <TableHeaderColumn className="hidden sm:table-cell w-28 xl:w-36 2xl:w-48">
          {t('common.table-header-column.status', 'Status')} / {t('common.table-header-column.time', 'Time')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden xl:table-cell xl:w-44 2xl:w-60">
          {t('common.table-header-column.employee', 'Employee')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell xl:w-44 2xl:w-60">
          {t('common.table-header-column.guest', 'Guest')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">
          {t('common.table-header-column.service', 'Service')}
        </TableHeaderColumn>
        <TableHeaderColumn className="hidden xl:table-cell">
          {t('common.table-header-column.message', 'Message')}
        </TableHeaderColumn>

        {/*Mobile*/}
        <TableHeaderColumn className="sm:hidden w-14">
          {t('common.table-header-column.status', 'Status')}
        </TableHeaderColumn>

        <TableHeaderColumn className="sm:hidden max-w-16">
          {t('common.table-header-column.time-service', 'Time / Service')}
        </TableHeaderColumn>

        <TableHeaderColumn className="sm:hidden max-w-16">
          {t('common.table-header-column.name-assign', 'Guest / Assigned')}
        </TableHeaderColumn>

        <TableHeaderColumn className="w-4"></TableHeaderColumn>
      </Table>
    </>
  );
}

function AppointmentRow(props: {
  item: NailAppointmentItemFragment;
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: () => void;
}) {
  const { item, onClick, actions } = props;
  const company = useFocusedCompany();
  const timezone = company.settings.timezone;

  const employee = getFragmentData(PickedNailAppointmentEmployeeFragment, item.employees);
  const userId = employee[0] ? employee[0].user.userId : '';
  const userFirstName = employee[0] ? employee[0].user.firstName : '';
  const serviceName = item.products[0] ? item.products[0].pickedProduct.title : '';
  const colorCode = employee[0] ? employee[0].user.colorCode : '';

  return (
    <>
      <TableRow key={item.id} className="cursor-pointer overflow-hidden" onClick={onClick}>
        {/*Desktop*/}
        <TableRowColumn className="hidden sm:table-cell sm:w-40 xl:w-36 2xl:w-48">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative flex items-center gap-2"
          >
            <AppointmentTableListConfirmationStatus appointmentId={item.id} status={item.confirmationStatus} />
            <p className="font-bold">{nailAppointmentTimeFormat(item.startTime ?? '', timezone)}</p>
          </div>
        </TableRowColumn>

        <TableRowColumn className="hidden xl:table-cell xl:w-44 2xl:w-60">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <VirtualUserSelectionDepend appointmentId={item.id} userFirstName={userFirstName} userId={userId ?? ''} />
          </div>
        </TableRowColumn>

        {/* Name */}
        <TableRowColumn className="hidden sm:table-cell max-w-[80px] xl:w-44 2xl:w-60">
          <span>
            {item.customerVariant.name.length <= 15
              ? item.customerVariant.name
              : `${item.customerVariant.name.slice(0, 15)}...`}
          </span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        {/* Service */}
        <TableRowColumn className="hidden sm:table-cell max-w-12 xl:max-w-32 truncate">
          <span className="font-semibold truncate ">{serviceName}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        <TableRowColumn className="hidden xl:table-cell max-w-0.5 truncate overflow-hidden break-all">
          {item.message}
        </TableRowColumn>

        <TableRowColumn className="hidden space-x-4">
          <div className="flex items-center justify-end ">
            <div className="max-sm:hidden">{actions?.({ id: item.id })}</div>
            <ChatBubbleOvalLeftEllipsisIcon
              className={classNames('fill-yellow-500 w-4 h-4 xl:hidden', {
                hidden: item.message?.length === 0,
              })}
            />
            <ChevronRightIcon className="w-7 fill-gray-400" />
          </div>
        </TableRowColumn>

        {/*Mobile*/}
        <TableRowColumn className="sm:hidden w-14">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <AppointmentTableListConfirmationStatus appointmentId={item.id} status={item.confirmationStatus} />
          </div>
        </TableRowColumn>

        <TableRowColumn className="sm:hidden ">
          <p className="font-bold">{nailAppointmentTimeFormat(item.startTime ?? '', timezone)}</p>
          <p className="truncate text-gray-400 max-w-20 min-[375px]:max-w-28 min-[425px]:max-w-36 truncate">
            {serviceName}
          </p>
        </TableRowColumn>

        <TableRowColumn className="sm:hidden space-y-1">
          <p className="text-gray-900 max-w-20 min-[375px]:max-w-28 min-[425px]:max-w-36 truncate">
            {item.customerVariant.name}
          </p>
          <p className="flex">
            <EmployeeDot colorCode={colorCode} />
            <span className="text-gray-400 max-w-20 min-[375px]:max-w-24 min-[425px]:max-w-28 truncate">
              {userFirstName}
            </span>
          </p>
        </TableRowColumn>

        <TableRowColumn className="xl:hidden w-4">
          <div className="flex items-center justify-end">
            <ChatBubbleOvalLeftEllipsisIcon
              className={classNames('fill-yellow-500 w-4 h-4 xl:hidden', {
                hidden: item.message?.length === 0,
              })}
            />
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export function NailAppointmentIcon(props: { status: NailAppointmentConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case NailAppointmentConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case NailAppointmentConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case NailAppointmentConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}

function nailAppointmentTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
