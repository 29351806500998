import useCreateHoliday from '@client/page/management/holiday/holiday-create/logic/use-create-holiday.ts';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/browser';
import HolidayDatePicker from '@client/page/management/holiday/holiday-create/component/date-picker.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import useCheckOverlapService, {
  HOLIDAY_OVERLAP_SERVICE,
} from '@client/page/management/holiday/holiday-create/logic/use-check-overlap-service.ts';
import { useModalManager } from '@packages/ui/modal/modal-manager-context.ts';
import OverlapDialog from '@client/page/management/holiday/holiday-create/component/overlap-dialog.tsx';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import validateTime from '@client/page/management/holiday/holiday-create/util/validate-time.ts';

interface HolidayCreateForm {
  description: string;
  isAllDay: boolean;
  startTime: string;
  endTime: string;
}

const DESCRIPTION_MAX_LENGTH = 200;
export default function HolidayCreate(props: { onClose: () => void }) {
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const company = useFocusedCompany();
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const overLapDialogManager = useModalManager();

  const { client } = useCheckOverlapService();
  const [create, { error }] = useCreateHoliday();
  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  const {
    control,
    watch,
    setError,
    formState: { errors },
    handleSubmit,
    setValue,
    clearErrors,
  } = useForm<HolidayCreateForm>({
    defaultValues: {
      description: '',
      isAllDay: true,
      startTime: moment().tz(company.settings.timezone).format('YYYY-MM-DDT00:00'),
      endTime: moment().tz(company.settings.timezone).format('YYYY-MM-DDT23:59'),
    },
  });

  const onSubmit = (data: HolidayCreateForm) => {
    const errors = validateTime(data.startTime, data.endTime);
    const errorNumber = Object.keys(errors).length;

    if (errorNumber > 0) {
      return;
    }

    const startTimeGMT = moment(data.startTime).tz(company.settings.timezone).format('Z');
    const endTimeGMT = moment(data.endTime).tz(company.settings.timezone).format('Z');

    client
      .query({
        query: HOLIDAY_OVERLAP_SERVICE,
        variables: {
          branchId: branchId,
          input: {
            startTime: data.startTime + startTimeGMT,
            endTime: data.endTime + endTimeGMT,
          },
        },
      })
      .then((result) => {
        if (
          result.data.checkOverlapService.appointment == 0 &&
          result.data.checkOverlapService.onlineOrder == 0 &&
          result.data.checkOverlapService.reservation == 0
        ) {
          createHoliday(data, startTimeGMT, endTimeGMT);
        } else {
          overLapDialogManager.showDialog((onClose) => (
            <OverlapDialog
              onCLose={onClose}
              data={result.data.checkOverlapService}
              action={() => {
                onClose();
                createHoliday(data, startTimeGMT, endTimeGMT);
              }}
            />
          ));
        }
      })
      .catch(() => {
        showAlert({
          title: t('holiday.holiday-create.alert.overlap-query.error.title', 'Failure'),
          message: t('holiday.holiday-create.alert.overlap-query.error.message', 'Can not get overlap query data'),
          status: 'error',
        });
      });
  };

  const createHoliday = (data: HolidayCreateForm, startTimeGMT: string, endTimeGMT: string) => {
    create({
      variables: {
        branchId: branchId,
        input: {
          description: data.description,
          startTime: data.startTime + startTimeGMT,
          endTime: data.endTime + endTimeGMT,
        },
      },
    })
      .then(() => {
        props.onClose();
        showAlert({
          title: t('holiday.holiday-create.alert.success.title', 'Success'),
          message: t('holiday.holiday-create.alert.success.message', 'Holiday has been created successfully.'),
          status: 'success',
        });
      })
      .catch((err) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);
          for (const field in validationError) {
            setError(
              field as keyof HolidayCreateForm,
              {
                type: 'server',
                message: t(validationError[field] ?? 'validation-error.common.alert'),
              },
              { shouldFocus: true }
            );
          }
        }

        captureException(err);
      });
  };

  const handleToggle = (value: boolean) => {
    const startTime = moment(watch('startTime')).utc(true);
    const endTime = moment(watch('endTime')).utc(true);

    if (value) {
      setValue('startTime', startTime.set('hour', 0).set('minute', 0).format('YYYY-MM-DDTHH:mm'));
      setValue('endTime', endTime.set('hour', 23).set('minute', 59).format('YYYY-MM-DDTHH:mm'));
      setValue('isAllDay', true);
      return;
    }
    setValue('isAllDay', false);
  };

  return (
    <>
      <Card>
        <form
          onSubmit={(e) => {
            clearErrors();
            void handleSubmit(onSubmit)(e);
          }}
        >
          <CardHeader title={t('holiday.holiday-create.header', 'Create holiday')} onCrossButton={props.onClose} />

          <CardContent>
            <FormLayout>
              {validationError.time && (
                <AlertError
                  className="mb-4"
                  title="Error"
                  message={t(validationError.time, 'Time range is overlap, please select another date or hour')}
                />
              )}
              <div>
                <div className="flex flex-col sm:flex-row gap-4">
                  <FormItem title={t('holiday.holiday-create.form.start-date', 'Start date')} className="max-w-md">
                    <Controller
                      name="startTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <HolidayDatePicker
                          format="date"
                          time={value}
                          setTime={(startDate) => {
                            const formatString = moment(startDate).format('YYYY-MM-DD');
                            const currentStartDate = watch('startTime');

                            const updatedStartTime = `${formatString}T${currentStartDate.slice(11)}`;

                            onChange(updatedStartTime);
                          }}
                        />
                      )}
                    />
                  </FormItem>

                  <FormItem title={t('holiday.holiday-create.form.end-date', 'End date')} className="max-w-md ">
                    <Controller
                      name="endTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <HolidayDatePicker
                          format="date"
                          time={value}
                          setTime={(startDate) => {
                            const formatString = moment(startDate).format('YYYY-MM-DD');
                            const currentStartDate = watch('endTime');

                            const updatedStartTime = `${formatString}T${currentStartDate.slice(11)}`;

                            onChange(updatedStartTime);
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>

                {validateTime(watch('startTime'), watch('endTime')).date && (
                  <p className="mt-2 text-sm text-red-600">
                    {t(
                      validateTime(watch('startTime'), watch('endTime')).date ?? '',
                      'Start date must be smaller than end date'
                    )}
                  </p>
                )}

                {validationError.date && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {t(validationError.date, 'Start date must smaller than end date')}
                  </p>
                )}
              </div>

              <FormItem title={t('holiday.holiday-create.form.all-day', 'All day')}>
                <Controller
                  name="isAllDay"
                  control={control}
                  render={({ field: { value } }) => <ToggleInput value={value} onChange={handleToggle} />}
                />
              </FormItem>

              {watch('isAllDay') || (
                <div>
                  <div className="flex flex-col sm:flex-row gap-4">
                    <FormItem title={t('holiday.holiday-create.form.start-hour', 'Start hour')} className="max-w-md ">
                      <Controller
                        name="startTime"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <HolidayDatePicker
                            format="hour"
                            time={value}
                            setTime={(startDate) => {
                              const formatString = moment(startDate).format('HH:mm');
                              const currentStartDate = watch('startTime');

                              const updatedStartTime = `${currentStartDate.slice(0, 10)}T${formatString}`;

                              onChange(updatedStartTime);
                            }}
                          />
                        )}
                      />
                    </FormItem>

                    <FormItem title={t('holiday.holiday-create.form.end-hour', 'End hour')} className="max-w-md">
                      <Controller
                        name="endTime"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <HolidayDatePicker
                            format="hour"
                            time={value}
                            setTime={(startDate) => {
                              const formatString = moment(startDate).format('HH:mm');
                              const currentStartDate = watch('endTime');

                              const updatedStartTime = `${currentStartDate.slice(0, 10)}T${formatString}`;

                              onChange(updatedStartTime);
                            }}
                          />
                        )}
                      />
                    </FormItem>
                  </div>

                  {validateTime(watch('startTime'), watch('endTime')).hour && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {t(
                        validateTime(watch('startTime'), watch('endTime')).hour ?? '',
                        'Start hour must be smaller than end hour'
                      )}
                    </p>
                  )}

                  {validationError.hour && (
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {t(validationError.hour, 'Start hour must smaller than end hour')}
                    </p>
                  )}
                </div>
              )}

              <FormItem
                subtitle={`(${String(watch('description').length)}/${String(DESCRIPTION_MAX_LENGTH)})`}
                className="max-w-lg"
                title={t('service.service-detail.product.edit.form-input.description.title', 'Description')}
              >
                <Controller
                  control={control}
                  name="description"
                  rules={{
                    maxLength: {
                      value: 200,
                      message: t('holiday.holiday-create.form.maxLength', 'Max length is 200'),
                    },
                  }}
                  defaultValue={''}
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      className="w-full p-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200 min-h-36"
                      placeholder={t(
                        'service.service-detail.product.edit.form-input.description.placeholder',
                        'Description'
                      )}
                      value={value}
                      name=""
                      onChange={onChange}
                      maxLength={DESCRIPTION_MAX_LENGTH}
                    />
                  )}
                />
                {errors.description && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.description.message}
                  </p>
                )}
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <PrimaryButton>
              <input type="submit" value={t('common.button.create', 'create')} />
            </PrimaryButton>
          </CardFooter>
        </form>
      </Card>

      <Transition.Root show={overLapDialogManager.dialog != null} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-30"
          onClose={() => {
            overLapDialogManager.closeDialog();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-screen sm:max-w-lg">
                  {overLapDialogManager.dialog != null && <div>{overLapDialogManager.dialog}</div>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
