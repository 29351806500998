import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { ChartOptions, type ChartData } from 'chart.js';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useReservationGroupByGuest
  from '@client/page/management/table-reservation/analytic-new/logic/use-reservation-group-by-guest.ts';
import { Dayjs } from 'dayjs';
import {
  useFocusedBranchContext
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';

export default function ReservationGroupByGuest(props: {
  startTime: Dayjs;
  endTime: Dayjs;
}) {
  const { branch } = useFocusedBranchContext();

  const { data } = useReservationGroupByGuest(
    branch?.id,
    props.startTime.format(),
    props.endTime.format(),
  );

  const [chartData, setChartData] = useState<ChartData<'bar'>>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!data) {
      return;
    }

    const aggregateData = data.analytic_reservationsByGuestCount;

    // One day
    const labels = aggregateData.map((item) =>
      item.guestCount.toString(),
    );

    // Total reservations
    const totalReservations = aggregateData.reduce(
      (acc, item) => acc + item.count,
      0,
    );

    const everydayTrends = aggregateData.map(
      (item) => (item.count / totalReservations) * 100,
    );

    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Order percentage',
          data: everydayTrends,
          backgroundColor: 'rgba(75,130,192,0.5)',
          borderColor: 'rgba(75,130,192,1)',
          borderWidth: 2,
          borderRadius: 8,
        },
      ],
    });
  }, [data, setChartData]);

  const options: ChartOptions<'bar'> = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    aspectRatio: 2,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function(value) {
            if (Number.isInteger(value)) {
              return `${value.toString()}%`; //
            }
            return value; // Append '%' to y-axis labels
          },
        },
        title: {
          display: true,
          text: 'Percentage',
        },
      },
      x: {
        title: {
          display: true,
          text: 'Number of guests',
        },
      },
    },
  };

  return (
    <Card className="">
      <CardHeader title="Number of guests" />
      <CardContent>
        <Bar options={options} data={chartData} />
      </CardContent>
    </Card>
  );
}
