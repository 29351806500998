import Loading from '@packages/ui/loading.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useGoogleIntegration } from '@client/page/settings/google/google-integration-list/logic/use-google-integrations.ts';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { captureException } from '@sentry/browser';
import { createGoogleOAuthCallback } from '@client/page/settings/google/google-integration-list/logic/use-google-oauth-callback.ts';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import { useSetGoogleCalendarService } from '@client/page/settings/google/google-integration-list/logic/use-google-calendar-set-sync.ts';
import { useStartSyncGoogleCalendarService } from '@client/page/settings/google/google-integration-list/logic/use-start-sync-google-calendar.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import FormItem from '@packages/ui/form/form-item.tsx';
import { GoogleCalendarSyncService } from '@client/graphql/types/graphql.ts';
import { useGoogleIntegrationDelete } from '@client/page/settings/google/google-integration-list/logic/use-google-intergration-delete.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import CardContent from '@packages/ui/card/card-content.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import { useTranslate } from '@tolgee/react';

const GoogleIntegrationListPage = () => {
  const company = useFocusedCompany();
  const branchID = useFocusedBranchContext().branch?.id ?? '';

  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const { data, error, loading, refetch } = useGoogleIntegration(company.id);
  const [setGoogleCalendarService, { loading: setCalendarLoading }] = useSetGoogleCalendarService();
  const [sync] = useStartSyncGoogleCalendarService();
  const [deleteIntegration] = useGoogleIntegrationDelete();

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title="Error" message={error.message} />;
  }

  if (!data) {
    return <>Data is empty</>;
  }

  if (data.googleIntegrations.length === 0) {
    return (
      <ContentLayout>
        <PrimaryContent>
          <Card>
            <CardHeader title="Google Integrations" />
            <CardContent>
              <PrimaryButton
                onClick={() => {
                  createGoogleOAuthCallback(company.id)
                    .then(({ data }) => {
                      window.location.href = data.googleOAuthUrl;
                    })
                    .catch(captureException);
                }}
              >
                {t('google-integration.list.button.add-integration', 'Add Integration')}
              </PrimaryButton>
            </CardContent>
          </Card>
        </PrimaryContent>
      </ContentLayout>
    );
  }

  const mainIntegration = data.googleIntegrations[0];
  const mainCalendarIntegration = mainIntegration?.googleCalendarIntegrations
    ? mainIntegration.googleCalendarIntegrations[0]
    : undefined;

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title="Google Integrations" />

          <CardTableContent>
            <div className="divide divide-y-2">
              <FormItem className="px-6 py-4" title={t('google-integration.list.account.header', 'Account')}>
                <div className="flex justify-between">
                  <p className="text-sm text-gray-600">{mainIntegration?.email}</p>
                  <SecondaryButton
                    onClick={() => {
                      deleteIntegration({
                        variables: {
                          id: mainIntegration?.id ?? '',
                        },
                      })
                        .then(() => {
                          showAlert({
                            title: t('google-integration.list.disconnect.alert.header', 'Success'),
                            message: t(
                              'google-integration.list.disconnect.alert.description',
                              'Integration has been deleted successfully.'
                            ),
                            status: 'success',
                          });
                          void refetch();
                        })
                        .catch((e: Error) => {
                          showAlert({
                            title: 'Error',
                            message: e.message,
                            status: 'success',
                          });
                        });
                    }}
                  >
                    {t('google-integration.list.button.disconnect', 'Disconnect')}
                  </SecondaryButton>
                </div>
              </FormItem>
              <FormItem
                className="px-6 py-4"
                title={t('google-integration.list.sync.header', 'Appointment calendar sync')}
              >
                <p className="text-sm text-gray-600 mb-3">
                  {t('google-integration.list.sync.description', 'Sync all appointmnet to your calendar in realtime')}
                </p>
                <div className="flex justify-between">
                  <div className="flex  items-center">
                    <ToggleInput
                      value={mainCalendarIntegration ? mainCalendarIntegration.enabled : false}
                      disabled={setCalendarLoading}
                      onChange={() => {
                        setGoogleCalendarService({
                          variables: {
                            integrationID: mainIntegration?.id ?? '',
                            service: GoogleCalendarSyncService.Appointment,
                            enable: !mainCalendarIntegration?.enabled,
                            branchID: branchID,
                          },
                        })
                          .then(() => {
                            showAlert({
                              title: t('google-integration.list.connect.alert.title', 'Success'),
                              message: t(
                                'google-integration.list.connect.alert.description',
                                'Integration has been connected to google calendar system.'
                              ),
                              status: 'success',
                            });
                          })
                          .catch(captureException);
                      }}
                    />
                    {setCalendarLoading && (
                      <div className="scale-[0.65]">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  <SecondaryButton
                    onClick={() => {
                      sync({
                        variables: {
                          integrationID: mainIntegration?.id ?? '',
                          service: GoogleCalendarSyncService.Appointment,
                          branchID: branchID,
                        },
                      })
                        .then(() => {
                          showAlert({
                            title: t('google-integration.list.sync.alert.title', 'Success'),
                            message: t('google-integration.list.sync.alert.description', 'Integrate successfully'),
                            status: 'success',
                          });
                        })
                        .catch(captureException);
                    }}
                  >
                    {t('google-integration.list.sync.button', ' Manual sync')}
                  </SecondaryButton>
                </div>
              </FormItem>
            </div>
          </CardTableContent>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
};

export default GoogleIntegrationListPage;
{
  /*<Table*/
}
{
  /*  data={data.googleIntegrations ?? []}*/
}
{
  /*  build={(integration) => {*/
}
{
  /*    const appointmentIntegration = integration.googleCalendarIntegrations?.find((e) => {*/
}
{
  /*      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition*/
}
{
  /*      return e.service == GoogleCalendarSyncService.Appointment;*/
}
{
  /*    });*/
}

{
  /*    return (*/
}
{
  /*      <TableRow key={integration.id}>*/
}
{
  /*        <TableRowColumn>{integration.email}</TableRowColumn>*/
}
{
  /*        <TableRowColumn alignment={TableCellAlignment.Right}>*/
}
{
  /*          <div className="flex items-center justify-end gap-4">*/
}
{
  /*            <SecondaryButton*/
}
{
  /*              onClick={() => {*/
}
{
  /*                sync({*/
}
{
  /*                  variables: {*/
}
{
  /*                    integrationID: integration.id,*/
}
{
  /*                    service: GoogleCalendarSyncService.Appointment,*/
}
{
  /*                    branchID: branchID,*/
}
{
  /*                  },*/
}
{
  /*                }).catch(captureException);*/
}
{
  /*              }}*/
}
{
  /*            >*/
}
{
  /*              Sync*/
}
{
  /*            </SecondaryButton>*/
}
{
  /*            <ToggleInput*/
}
{
  /*              value={appointmentIntegration?.enabled ?? false}*/
}
{
  /*              disabled={setCalendarLoading}*/
}
{
  /*              onChange={() => {*/
}
{
  /*                setGoogleCalendarService({*/
}
{
  /*                  variables: {*/
}
{
  /*                    integrationID: integration.id,*/
}
{
  /*                    service: GoogleCalendarSyncService.Appointment,*/
}
{
  /*                    enable: !appointmentIntegration?.enabled,*/
}
{
  /*                    branchID: branchID,*/
}
{
  /*                  },*/
}
{
  /*                }).catch(captureException);*/
}
{
  /*              }}*/
}
{
  /*            />*/
}
{
  /*          </div>*/
}
{
  /*        </TableRowColumn>*/
}
{
  /*      </TableRow>*/
}
{
  /*    );*/
}
{
  /*  }}*/
}
{
  /*>*/
}
{
  /*  <TableHeaderColumn alignment={TableCellAlignment.Left}>Account</TableHeaderColumn>*/
}
{
  /*  <TableHeaderColumn alignment={TableCellAlignment.Right}>Action</TableHeaderColumn>*/
}
{
  /*</Table>*/
}
