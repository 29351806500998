import { graphql } from '@client/graphql/types';

export const NailAppointmentFragment = graphql(`
  fragment NailAppointmentItem on NailAppointment {
    id
    branchId
    customerVariantId
    customerVariant {
      id
      name
      phone
      email
      customerId
    }
    cancelReason {
      id
      reason
      appointmentId
      note
    }
    confirmationStatus
    startTime
    endTime
    message
    deleted
    teams {
      ...PickedNailAppointmentTeamItem
    }
    employees {
      ...PickedNailAppointmentEmployeeItem
    }
    products {
      id
      appointmentId
      pickedProduct {
        id
        enabled
        title
        description
        images
        code
        menuProduct {
          id
          configurations {
            id
            values {
              id
            }
          }
        }
        configurations {
          id
          title
          value
          price
        }
      }
    }
  }
`);
