import Table from '@packages/ui/table/table.tsx';
import {
  OnlineOrderDeliveryMode,
  OnlineOrderListItemFragment,
  OnlineOrderState,
} from '@client/graphql/types/graphql.ts';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import moment from 'moment-timezone';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import { ShoppingBagIcon, TruckIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import OnlineOrderSetButtons from '@client/page/management/online-order/dashboard/component/order-online-state-button.tsx';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { groupBy } from 'graphql/jsutils/groupBy';
import { Fragment } from 'react';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import CurrencyView from '@packages/core/utils/currency-view.tsx';

const OnlineOrderHistoryTable = (props: {
  orders: readonly OnlineOrderListItemFragment[];
  loading: boolean;
  branchID: string;
  navigationPrefix?: string;
  refetch: () => void;
  error?: string;
}) => {
  const { t } = useTranslate();
  const company = useFocusedCompanyContext();

  if (props.orders.length === 0) {
    return <div className="p-4 border-t">{t('online-order.dashboard.no-orders-found')}</div>;
  }

  const dateGroups = groupBy(props.orders, (item) => {
    return moment(item.createdAt).tz(company.settings.timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, onlineOrders]) => {
    const sortedReservations = Array.from(onlineOrders);
    sortedReservations.sort((a, b) => {
      return moment(a.createdAt).isBefore(moment(b.createdAt)) ? 1 : -1;
    });

    return {
      date,
      onlineOrder: sortedReservations,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    return moment(a.date).isBefore(moment(b.date)) ? 1 : -1;
  });

  return (
    <Table
      loading={props.loading}
      error={props.error}
      refetch={props.refetch}
      data={dates}
      build={(order) => {
        return (
          <Fragment key={order.date}>
            <TableSection key={order.date} colSpan={6}>
              <div className="flex justify-start py-1 sm:pl-2">
                <span className="font-bold text-blue-500">
                  {order.date == moment().tz(company.settings.timezone).format('DD MMM')
                    ? t('appointment.history.list.section.today', 'Today')
                    : order.date}
                </span>
              </div>
            </TableSection>

            {order.onlineOrder.map((onlineOrder) => (
              <OnlineOrderRow
                key={onlineOrder.id}
                order={onlineOrder}
                navigationPrefix={props.navigationPrefix}
                timezone={company.settings.timezone}
              />
            ))}
          </Fragment>
        );
      }}
    >
      {/*Mobile*/}
      <TableHeaderColumn className="sm:hidden" alignment={TableCellAlignment.Center}>
        {t('common.table-header-column.type')}
      </TableHeaderColumn>
      <TableHeaderColumn className="sm:hidden" alignment={TableCellAlignment.Center}>
        {t('common.table-header-column.status')}
      </TableHeaderColumn>
      <TableHeaderColumn className="sm:hidden max-w-16">
        {t('common.table-header-column.guest-time', 'Order/ Completed time')}
      </TableHeaderColumn>
      <TableHeaderColumn className="sm:hidden w-24">
        {t('common.table-header-column.name-message', 'Guest name/ Message')}
      </TableHeaderColumn>

      {/*Desktop*/}
      <TableHeaderColumn className="hidden sm:table-cell text-center">
        {t('common.table-header-column.type')}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell  text-center">
        {t('common.table-header-column.guest')}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell text-center">
        {t('common.table-header-column.time')}
      </TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell">{t('common.table-header-column.products')}</TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell">{t('common.table-header-column.price')}</TableHeaderColumn>
      <TableHeaderColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Right}></TableHeaderColumn>
    </Table>
  );
};

function OnlineOrderRow(props: {
  order: OnlineOrderListItemFragment;
  timezone: string;
  navigationPrefix: string | undefined;
}) {
  const navigate = useNavigate();
  const { order, timezone } = props;
  const { t } = useTranslate();

  return (
    <TableRow
      key={order.id}
      onClick={() => {
        if (props.navigationPrefix) {
          navigate(`${props.navigationPrefix}/${order.id}`);
        } else {
          navigate(order.id);
        }
      }}
      className={classNames({ 'bg-amber-50': isWaitingForConfirm(order.state) })}
    >
      {/*Mobile*/}
      <TableRowColumn className="sm:hidden">
        <DeliveryType deliveryMode={order.deliveryMode} distance={order.distanceToRestaurant} />
      </TableRowColumn>
      <TableRowColumn alignment={TableCellAlignment.Center} className="sm:hidden">
        <OnlineOrderSetButtons
          navigationPrefix={props.navigationPrefix}
          onlineOrderId={order.id}
          onlineOrderState={order.state}
        />
      </TableRowColumn>
      <TableRowColumn className="sm:hidden">
        <p className="font-bold">{moment(order.userCreatedAt).tz(timezone).format('HH:mm')}</p>
        {order.estimatedDuration ? (
          <p className="font-bold">
            {moment(order.userCreatedAt).add(order.estimatedDuration, 'minutes').tz(timezone).format('HH:mm')}
          </p>
        ) : (
          <p className="text-gray-400 italic">{t('online-order.list.table.completeTime.empty', 'Not set')}</p>
        )}
      </TableRowColumn>
      <TableRowColumn className="sm:hidden">
        <p className="font-semibold max-w-28 truncate">{order.guestName}</p>
        <p className="text-gray-500 text-sm max-w-28 truncate">{order.message}</p>
      </TableRowColumn>

      {/*Desktop*/}
      <TableRowColumn className="w-10 hidden sm:table-cell">
        <DeliveryType deliveryMode={order.deliveryMode} distance={order.distanceToRestaurant} />
      </TableRowColumn>
      <TableRowColumn className="hidden sm:table-cell text-center">{order.guestName}</TableRowColumn>
      <TableRowColumn className="hidden sm:table-cell text-center">
        {moment(order.userCreatedAt).tz(timezone).format('HH:mm')}
      </TableRowColumn>
      <TableRowColumn className="hidden md:table-cell">{order._count.onlineOrderProducts} product(s)</TableRowColumn>
      <TableRowColumn className="hidden md:table-cell">
        <CurrencyView price={order.totalPrice} />
      </TableRowColumn>
      <TableRowColumn className="hidden sm:table-cell" alignment={TableCellAlignment.Right}>
        <OnlineOrderSetButtons
          navigationPrefix={props.navigationPrefix}
          onlineOrderId={order.id}
          onlineOrderState={order.state}
        />
      </TableRowColumn>
    </TableRow>
  );
}

const DeliveryType = (props: { deliveryMode: OnlineOrderDeliveryMode; distance: number | null | undefined }) => {
  let additionalInfo = '';
  if (props.deliveryMode === OnlineOrderDeliveryMode.Delivery && props.distance) {
    additionalInfo = ` ${(props.distance / 1000).toFixed(2)} km`;
  }

  return (
    <div className="flex flex-col items-center">
      {props.deliveryMode == OnlineOrderDeliveryMode.Delivery ? (
        <>
          <TruckIcon className="w-6 h-6" />{' '}
          <p className="inline-block text-center text-xs md:text-sm line-clamp-1">{additionalInfo}</p>
        </>
      ) : (
        <ShoppingBagIcon className="w-6 h-6" />
      )}
    </div>
  );
};

const isWaitingForConfirm = (onlineOrderState: OnlineOrderState) => {
  return onlineOrderState === OnlineOrderState.WaitingForConfirmation;
};

export default OnlineOrderHistoryTable;
