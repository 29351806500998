import { graphql } from '@client/graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
    query reservationDailyTrends($branchId: ID!, $startTime: DateTime!, $endTime: DateTime!) {
        daily: analytic_totalReservations(
            branchId: $branchId
            startTime: $startTime
            endTime: $endTime
            lastDayCount: 1
        ) {
            totalPerRangeTime
            totalReservationPerDate {
                date
                totalReservations
            }
        }

        weekly: analytic_totalReservations(
            branchId: $branchId
            startTime: $startTime
            endTime: $endTime
            lastDayCount: 7
        ) {
            totalPerRangeTime
            totalReservationPerDate {
                date
                totalReservations
            }
        }

        monthly: analytic_totalReservations(
            branchId: $branchId
            startTime: $startTime
            endTime: $endTime
            lastDayCount: 30
        ) {
            totalPerRangeTime
            totalReservationPerDate {
                date
                totalReservations
            }
        }
    }
`);

export default function useReservationDailyTrends(
  branchId: string | undefined,
  startTime: string,
  endTime: string,
) {
  return useQuery(QUERY, {
    variables: {
      branchId: branchId ?? '',
      startTime,
      endTime,
    },
    skip: !branchId,
  });
}
