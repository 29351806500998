import { RouteObject } from 'react-router-dom';
import OnlineOrderDashboardSinglePage from '@client/page/management/online-order/dashboard/online-order-dashboard-single-page.tsx';
import OnlineOrderHistory from '@client/page/management/online-order/history/online-order-history.tsx';
import { OpeningTimeRouter } from '@client/page/management/opening-time/opening-time.router.tsx';
import OnlineOrderBranchSettingPage from './setting/online-order-branch-setting-page.tsx';
import OnlineOrderDetail from './detail/online-order-detail.tsx';

export const OnlineOrderRouter: RouteObject = {
  path: 'online-order',
  handle: {
    crumb: () => 'breadcumb.onlineOrder.title',
  },
  children: [
    {
      path: 'dashboard',
      children: [
        {
          path: '',
          element: <OnlineOrderDashboardSinglePage />,
        },
        {
          path: ':onlineOrderID',
          element: <OnlineOrderDetail />,
        },
      ],
    },
    {
      path: 'history',
      children: [
        {
          path: '',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.history',
          },
          element: <OnlineOrderHistory />,
        },
        {
          path: ':onlineOrderID',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.detail',
          },
          element: <OnlineOrderDetail />,
        },
      ],
    },
    {
      path: 'setting',
      handle: {
        crumb: () => 'breadcumb.onlineOrder.setting',
      },
      children: [
        OpeningTimeRouter,
        {
          path: 'general',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.setting.general',
          },
          element: <OnlineOrderBranchSettingPage />,
        },
        {
          path: 'pick-up',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.setting.pickUp',
          },
          element: <OnlineOrderBranchSettingPage />,
        },
        {
          path: 'delivery',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.setting.delivery',
          },
          element: <OnlineOrderBranchSettingPage />,
        },
        {
          path: 'printer',
          handle: {
            crumb: () => 'breadcumb.onlineOrder.setting.printer',
          },
          element: <OnlineOrderBranchSettingPage />,
        },
      ],
    },
  ],
};
