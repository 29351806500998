import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import MenuSelectionInput from 'ui/src/form/input/menu-selection-input';
import { useEffect, useState } from 'react';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardFooter from 'ui/src/card/card-footer';
import PrimaryButton from 'ui/src/button/primary-button';
import FormSection from '@packages/ui/form/form-section.tsx';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useTranslate } from '@tolgee/react';
import { useCompanySettings } from './logic/use-company-setting';
import { useUpdateCompanySettings } from './logic/use-update-company-settings';

const currencyType: string[] = ['VND', 'EUR', 'USD', 'CZK', 'SEK'];
const defaultLanguageType: string[] = ['en', 'vi', 'de'];

export default function CompanyAdvancedSettings() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const companyService = useCompanyService();

  const { data } = useCompanySettings(companyID);
  const [updateCompanySetting] = useUpdateCompanySettings();

  const [companyCurrency, setCompanyCurrency] = useState<string>('');
  const [companyDefaultLanguage, setCompanyDefaultLanguage] = useState<string>('de');

  useEffect(() => {
    setCompanyCurrency(data?.company?.settings.currency ?? '');
    setCompanyDefaultLanguage(data?.company?.settings.customerDefaultLanguage ?? 'de');
  }, [companyService.company?.logo, companyService.company?.name, data]);

  const onSubmit = () => {
    updateCompanySetting({
      variables: {
        id: data?.company?.settings.id ?? '',
        input: {
          currency: companyCurrency,
          customerDefaultLanguage: companyDefaultLanguage,
        },
      },
    })
      .then(() => {
        localStorage.setItem('language', companyDefaultLanguage);
        location.reload();
      })
      .catch((e) => {
        /* eslint-disable no-console */
        console.log(e);
      });
  };

  return (
    <Card>
      <CardHeader title={t('common.advanced-setting')} />

      <CardContent>
        <FormLayout>
          <FormSection
            title={t('common.companyID')}
            description={t('company-setting.company-description')}
          >
            <p className="font-semibold">{companyID}</p>
          </FormSection>

          <FormSection
            title={t('common.company-name')}
            description={t('company-setting.company-name')}
          >
            <p className="font-semibold">{companyService.company?.name}</p>
          </FormSection>

          <MenuSelectionInput
            title={t('common.currency')}
            data={currencyType}
            value={companyCurrency}
            onChange={(newValue) => {
              setCompanyCurrency(newValue);
            }}
            build={(item) => {
              return {
                id: item,
                name: item,
              };
            }}
            className="-my-2 w-full"
          />
          <MenuSelectionInput
            title={t('company-setting.advanced.default-language','Default language')}
            data={defaultLanguageType}
            value={companyDefaultLanguage}
            onChange={(newValue) => {
              setCompanyDefaultLanguage(newValue);
            }}
            build={(item) => {
              // Map language codes to country codes
              const languageCountryMap = {
                'en': 'us',
                'vi': 'vn',
                'de': 'de'
              };
              return {
                id: item,
                name: t('language.selector.'+item),
                country: languageCountryMap[item as keyof typeof languageCountryMap]
              };
            }}
            showFlag={true}
            className="-my-2 w-full"
          />
        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-32">
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              onSubmit();
            }}
          >
            {t('common.update')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
