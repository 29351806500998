import { OnlineOrderState } from '@client/graphql/types/graphql.ts';
import { OnlineOrderConfirmationQuickAction } from '@client/page/management/online-order/dashboard/component/online-order-confirmation-quick-action.tsx';

export default function OnlineOrderSetButtons(props: {
  onlineOrderId: string;
  onlineOrderState: OnlineOrderState;
  navigationPrefix?: string;
}) {
  const { onlineOrderState, onlineOrderId } = props;

  return (
    <>
      <OnlineOrderConfirmationQuickAction orderId={onlineOrderId} onlineOrderState={onlineOrderState} />
    </>
  );
}
