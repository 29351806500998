import useCustomer from '@client/page/management/customer/customer-detail/logic/use-customer.ts';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import Loading from '@packages/ui/loading.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import Table from '@packages/ui/table/table.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import { useCompanyCurrency, useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import moment from 'moment-timezone';
import CardContent from '@packages/ui/card/card-content.tsx';
import { ReactNode } from 'react';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CustomerAppointmentReport from '@client/page/management/customer/customer-detail/component/customer-appointment-report.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { NailAppointmentConfirmationStatus } from '@client/graphql/types/graphql.ts';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

export default function CustomerDetail() {
  const customerId = useParams().customerId ?? '';
  const { t } = useTranslate();
  const currency = useCompanyCurrency();
  const timezone = useFocusedCompany().settings.timezone;

  const { data, loading } = useCustomer(customerId);

  if (!data) {
    return <>Something went wrong</>;
  }

  if (loading) {
    return <Loading />;
  }

  const customerAppointmentHistory = data.customer.customerVariants.map((item) => item.nailAppointment);

  return (
    <>
      <Helmet>
        <title>Update User</title>
        <meta name="description" content="This page allows you to create a user" />
      </Helmet>
      <div className="space-y-4 2xl:max-w-[1500px]">
        <ContentLayout className="">
          <FullPrimaryContent>
            <Card>
              <CardHeader title={t('customer.detail.header', 'Customer detail')} withBackButton={true} />
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div className="flex flex-col xl:flex-row gap-4">
          <ContentLayout className="xl:basis-2/3">
            <FullPrimaryContent>
              <div className="space-y-4">
                {customerAppointmentHistory.length > 0 && (
                  <Card>
                    <CardHeader title={t('customer.detail.appointment.header.one', 'Customer appointment history')} />

                    <CardTableContent>
                      <CustomerAppointmentReport appointments={customerAppointmentHistory} />
                      <Table
                        data={customerAppointmentHistory}
                        build={(appointment) => {
                          const price = appointment?.products.at(0)?.pickedProduct.configurations.at(0)?.price ?? 0;
                          const title = appointment?.products[0]?.pickedProduct.title ?? '';

                          return (
                            <TableRow key={appointment?.id} className="hover:bg-gray-100">
                              <TableRowColumn className="max-w-14">
                                <p className="max-w-28 sm:max-w-52 truncate font-medium text-center">
                                  {appointment?.confirmationStatus == NailAppointmentConfirmationStatus.Confirmed && (
                                    <CheckCircleIcon className="text-green-500 w-8 h-8" />
                                  )}

                                  {appointment?.confirmationStatus == NailAppointmentConfirmationStatus.Cancelled && (
                                    <XCircleIcon className="text-red-500 w-8 h-8" />
                                  )}

                                  {appointment?.confirmationStatus == NailAppointmentConfirmationStatus.Waiting && (
                                    <QuestionMarkCircleIcon className="w-8 h-8 fill-yellow-500" />
                                  )}
                                </p>
                              </TableRowColumn>
                              <TableRowColumn>
                                <p className="w-full font-semibold hidden sm:block">
                                  {moment.tz(appointment?.startTime, timezone).format('DD MMMM HH:mm')}
                                </p>
                                <p className="w-full font-semibold sm:hidden">
                                  {moment.tz(appointment?.startTime, timezone).format('DD MMM HH:mm')}
                                </p>
                              </TableRowColumn>
                              <TableRowColumn>
                                <p className="max-w-24 sm:max-w-60 truncate">{title}</p>
                              </TableRowColumn>
                              <TableRowColumn>
                                <p className="max-w-28 sm:max-w-52 truncate font-semibold">
                                  {price && formatCurrency(price, currency)}
                                </p>
                              </TableRowColumn>
                            </TableRow>
                          );
                        }}
                      >
                        <TableHeaderColumn className="max-w-14">
                          {t('customer.list.appointment.status', 'Status')}
                        </TableHeaderColumn>
                        <TableHeaderColumn>{t('customer.detail.appointment.time', 'Time')}</TableHeaderColumn>
                        <TableHeaderColumn>{t('customer.list.appointment.service', 'Service')}</TableHeaderColumn>
                        <TableHeaderColumn>{t('customer.list.appointment.price', 'Price')}</TableHeaderColumn>
                      </Table>
                    </CardTableContent>
                  </Card>
                )}
              </div>
            </FullPrimaryContent>
          </ContentLayout>

          <ContentLayout className="grow">
            <FullPrimaryContent>
              {/*User role*/}
              <Card>
                <CardHeader title={t('customer.detail.customer-data.header', 'Customer Data')} />

                <CardContent>
                  <FormLayout>
                    <CustomerItem label={t('customer.detail.customer-data.name', 'Name')}>
                      <p className="text-gray-800 text-base">{data.customer.customerVariants.at(0)?.name ?? 'Empty'}</p>
                    </CustomerItem>

                    <CustomerItem label={t('customer.detail.customer-data.phone', 'Contact phone')}>
                      <div className="flex flex-col gap-2">
                        {removeDuplicates(data.customer.customerVariants.map((item) => item.phone)).map(
                          (item, index) => (
                            <div
                              className="flex items-center gap-1.5 border border-1 p-2 rounded-xl justify-between w-full shadow-sm hover:bg-gray-50"
                              key={index}
                            >
                              <span className="truncate text-gray-800 text-base px-2">{item}</span>

                              <div>
                                <div className="mt-2 md:mt-0 md:w-auto" key={index}>
                                  <a href={`tel:${item}`}>
                                    <SecondaryButton>
                                      {t('appointment.appointment-detail.phone.action.call', 'Call now')}
                                    </SecondaryButton>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </CustomerItem>

                    <CustomerItem label={t('customer.detail.customer-data.email', 'Email')}>
                      {removeDuplicates(data.customer.customerVariants.map((item) => item.email)).map((item, index) => (
                        <div
                          className="flex items-center gap-1.5 border border-1 p-2 rounded-xl justify-between w-full hover:bg-gray-50 shadow-sm"
                          key={index}
                        >
                          <span className="truncate text-gray-800 text-base px-2">{item}</span>

                          <div>
                            <div className="mt-2 md:mt-0 md:w-auto" key={index}>
                              <a href={`tel:${item}`}>
                                <SecondaryButton>
                                  {t('appointment.appointment-detail.phone.action.send-sms', 'Send SMS')}
                                </SecondaryButton>
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </CustomerItem>
                  </FormLayout>
                </CardContent>
              </Card>
            </FullPrimaryContent>
          </ContentLayout>
        </div>
      </div>
    </>
  );
}

function removeDuplicates<T>(array: T[]): T[] {
  return array.filter((item, index) => array.indexOf(item) === index);
}

function CustomerItem(props: { label: string; children: ReactNode }) {
  return (
    <div className={`flex flex-col md:flex-row justify-between items-start md:items-center`}>
      <div className="w-full">
        <p className="mb-2 text-sm text-gray-500">{props.label}</p>
        <div className="w-full">{props.children}</div>
      </div>
    </div>
  );
}
