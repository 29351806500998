import { graphql } from '@packages/core/graphql/types';
import { useQuery } from '@apollo/client';

export const COMPANIES_QUERY = graphql(`
    query Companies($offset: Int!, $limit: Int!) {
        companies(offset: $offset, limit: $limit) {
            id
            name
            category
            settings {
                customerDefaultLanguage
            }
        }
    }
`);

export function useCompanies(offset: number, limit: number) {
  return useQuery(COMPANIES_QUERY, {
    variables: {
      offset,
      limit,
    },
  });
}
