import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import useLoggers from '@client/page/management/logging/logic/use-loggers.ts';
import useLoggerQuantity from '@client/page/management/logging/logic/use-logger-quantity.ts';
import usePagination from '@packages/core/utils/pagination/use-pagination.ts';
import ApplicationErrorView from '@client/module/error/application-error-view.tsx';
import { Helmet } from 'react-helmet-async';
import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import Pagination from '@packages/core/utils/pagination/pagination.tsx';
import { useTranslate } from '@tolgee/react';
import LoggingTable from '@client/page/management/logging/component/logging-table.tsx';
import { DatePicker } from 'antd';
import { useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function Logging() {
  const { t } = useTranslate();
  const companyId = useCompanyId();

  const [startTime, setStartTime] = useState<Moment>(moment().subtract(30, 'days').startOf('day'));
  const [endTime, setEndTime] = useState<Moment>(moment().endOf('day'));

  const { data: quantityData } = useLoggerQuantity(companyId, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });
  const paginationInfo = usePagination(quantityData?.loggerQuantity ?? 0);
  const { data, error } = useLoggers(
    companyId,
    paginationInfo.info.current * paginationInfo.info.perPage,
    paginationInfo.info.perPage,
    {
      startTime: startTime.format('YYYY-MM-DDTHH:mm'),
      endTime: endTime.format('YYYY-MM-DDTHH:mm'),
    }
  );

  if (!data && error) {
    return <ApplicationErrorView error={error} />;
  }

  return (
    <ContentLayout>
      <Helmet>
        <title>Users</title>
        <meta name="description" content="This page displays a list of users and their roles" />
      </Helmet>
      <FullPrimaryContent>
        <Card>
          <CardHeader title={t('user-activity-list.header', 'User list')}>
            <RangePicker
              allowClear={false}
              value={[dayjs(startTime.toDate()), dayjs(endTime.toDate())]}
              onChange={(range) => {
                if (range?.[0] && range[1]) {
                  setStartTime(moment(range[0].toDate()).startOf('days'));
                  setEndTime(moment(range[1].toDate()).endOf('days'));
                }
              }}
            />
          </CardHeader>

          <CardTableContent>
            <LoggingTable items={data?.loggers} />
          </CardTableContent>

          <CardContent>
            <Pagination info={paginationInfo.info} setCurrent={paginationInfo.updateCurrent} />
          </CardContent>
        </Card>
      </FullPrimaryContent>
    </ContentLayout>
  );
}
