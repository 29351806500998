import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from 'ui/src/card/card-content';
import { useEffect } from 'react';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardFooter from 'ui/src/card/card-footer';
import PrimaryButton from 'ui/src/button/primary-button';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useTranslate } from '@tolgee/react';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { captureException } from '@sentry/browser';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { Controller, useForm } from 'react-hook-form';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { ApolloError } from '@apollo/client';
import PhoneInput from 'react-phone-number-input/min';
import { useUpdateCompany } from './logic/use-update-company-profile';


interface CompanyUpdateInput {
  imprintOwner?: string | null;
  imprintOwnerTaxId?: string | null;
  imprintCompanyName?: string | null;
  imprintCompanyTaxId?: string | null;
  imprintPhone?: string | null;
  imprintEmail?: string | null;
}

export default function CompanyImprintSetting() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const data = useCompanyService();
  const { showAlert } = useNotificationCenter();

  const [updateCompanyProfile] = useUpdateCompany();

  const form = useForm<CompanyUpdateInput>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit, setError } = form;

  useEffect(() => {
    form.reset({
      imprintOwner: data.company?.imprintOwner ?? '',
      imprintOwnerTaxId: data.company?.imprintOwnerTaxId ?? '',
      imprintCompanyName: data.company?.imprintCompanyName ?? '',
      imprintCompanyTaxId: data.company?.imprintCompanyTaxId ?? '',
      imprintPhone: data.company?.imprintPhone ?? '',
      imprintEmail: data.company?.imprintEmail ?? '',
    });
  }, [
    data.company?.imprintOwner,
    data.company?.imprintOwnerTaxId,
    data.company?.imprintCompanyName,
    data.company?.imprintCompanyTaxId,
    data.company?.imprintPhone,
    data.company?.imprintEmail,
    form,
  ]);

  const onSubmit = async (updateData: CompanyUpdateInput) => {
    try {
      const result = await updateCompanyProfile({
        variables: {
          companyID: companyID,
          input: {
            name: data.company?.name ?? '',
            imprintOwner: updateData.imprintOwner,
            imprintOwnerTaxId: updateData.imprintOwnerTaxId,
            imprintCompanyName: updateData.imprintCompanyName,
            imprintCompanyTaxId: updateData.imprintCompanyTaxId,
            imprintPhone: updateData.imprintPhone,
            imprintEmail: updateData.imprintEmail,
          },
        },
      });
      if (result.data) {
        showAlert({
          status: 'success',
          title: t('company.setting.imprint.update.alert.title', 'Update Success'),
          message: t('company.setting.imprint.update.alert.message', 'Update imprint successfully'),
        });
        location.reload();
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);
        for (const field in validationError) {
          setError(
            field as keyof CompanyUpdateInput,
            {
              type: 'server',
              message: t(validationError[field] ?? 'validation-error.common.alert'),
            },
            { shouldFocus: true },
          );
        }
      }
      captureException(err);
    }
  };

  return (
    <Card>
      <CardHeader title={'Imprint setting'} />

      <CardContent>
        <form
          className="space-y-6"
          onSubmit={() => {
            handleSubmit(onSubmit);
          }}
        >


          {/*Imprint */}
          <FormItem className="max-w-xl" title={t('company.imprint-setting.form-input.owner.title', 'Owner')}>
            <Controller
              name={'imprintOwner'}
              control={form.control}
              render={({ field }) => (
                <TextInput
                  type="text"
                  label="owner"
                  value={field.value ?? ''}
                  name="owner"
                  placeholder={t('company.imprint-setting.form-input.owner.placeholder', 'Owner')}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>

          {/*Google*/}
          <FormItem className="max-w-xl"
                    title={t('company.imprint-setting.form-input.owner-tax-id.title', 'Owner tax id')}>
            <Controller
              name={'imprintOwnerTaxId'}
              control={form.control}
              render={({ field }) => (
                <TextInput
                  type="text"
                  label="owner-tax-id"
                  value={field.value ?? ''}
                  name="owner-tax-id"
                  placeholder={t('company.imprint-setting.form-input.owner-tax-id.placeholder', 'Tax id')}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>
          {/*Imprint company Name*/}
          <FormItem className="max-w-xl"
                    title={t('company.imprint-setting.form-input.company-name.title', 'Company name')}>
            <Controller
              name={'imprintCompanyName'}
              control={form.control}
              render={({ field }) => (
                <TextInput
                  type="text"
                  label="imprintCompanyName"
                  value={field.value ?? ''}
                  name="imprintCompanyName"
                  placeholder={t('company.imprint-setting.form-input.company-name.placeholder', 'Company Name')}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>

          {/*Comp tax id*/}
          <FormItem className="max-w-xl"
                    title={t('company.imprint-setting.form-input.company-tax-id.title', 'Company tax Id')}>
            <Controller
              name={'imprintCompanyTaxId'}
              control={form.control}
              render={({ field }) => (
                <TextInput
                  type="text"
                  label="company-tax-id"
                  value={field.value ?? ''}
                  name="company-tax-id"
                  placeholder={t('company.imprint-setting.form-input.company-tax-id.placeholder', 'Company tax id')}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>

          <FormItem className="max-w-xl" title={t('company.imprint-setting.form-input.phone.title', 'Phone')}>
            <Controller
              name={'imprintPhone'}
              control={form.control}
              render={({ field }) => (
                <PhoneInput
                  style={{
                    width: '100%',
                    height: '',
                  }}
                  className="block w-full rounded-md border-0 p-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400
                         focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultCountry={'DE'}
                  placeholder={t('company.imprint-setting.form-input.phone.placeholder', 'Enter phone number')}
                  value={field.value ?? ''}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>

          <FormItem className="max-w-xl" title={t('company.imprint-setting.form-input.email.title', 'Email')}>
            <Controller
              name={'imprintEmail'}
              control={form.control}
              render={({ field }) => (
                <TextInput
                  type="email"
                  label="imprintEmail"
                  value={field.value ?? ''}
                  name="Email"
                  placeholder={t('company.imprint-setting.form-input.email.placeholder', 'Email')}
                  onChange={field.onChange}
                />
              )}
            />
          </FormItem>

        </form>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-32">
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              handleSubmit(onSubmit)().catch(captureException);
            }}
          >
            {t('common.update')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}
