/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            name\n            logo\n            category\n            instagramLink\n            facebookLink\n            googleLink\n            tiktokLink\n            imprintOwner\n            imprintOwnerTaxId\n            imprintCompanyName\n            imprintCompanyTaxId\n            imprintPhone\n            imprintEmail\n            settings {\n                currency\n                timezone\n                customerDefaultLanguage\n                notificationEmails\n            }\n        }\n    }\n": types.CompanyDocument,
    "\n    query Companies($offset: Int!, $limit: Int!) {\n        companies(offset: $offset, limit: $limit) {\n            id\n            name\n            category\n            settings {\n                customerDefaultLanguage\n            }\n        }\n    }\n": types.CompaniesDocument,
    "\n  mutation preSignPdfUpload {\n    preSignPdfUpload {\n      url\n      path\n    }\n  }\n": types.PreSignPdfUploadDocument,
    "\n  mutation GetPreSignUpload($filename: String!) {\n    preSignImageUpload(filename: $filename) {\n      origin\n      originPath\n      thumbnail\n      thumbnailPath\n    }\n  }\n": types.GetPreSignUploadDocument,
    "\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n": types.PickedNailAppointmentTeamItemFragmentDoc,
    "\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n      colorCode\n    }\n  }\n": types.PickedNailAppointmentEmployeeItemFragmentDoc,
    "\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n": types.BranchItemFragmentDoc,
    "\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n": types.MenuItemFragmentDoc,
    "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n": types.MenuProductConfigurationItemFragmentDoc,
    "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n": types.MenuProductConfigurationValueItemFragmentDoc,
    "\n    fragment MenuProductItem on MenuProduct {\n        id\n        code\n        title\n        priceMode\n        description\n        images\n        ingredients\n        labels\n        priceMode\n        discountable\n        allergics\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            id\n            title\n            type\n            values {\n                ...MenuProductConfigurationValueItem\n            }\n        }\n\n        menuId\n        sectionId\n    }\n": types.MenuProductItemFragmentDoc,
    "\n    fragment MenuSectionItem on MenuSection {\n        id\n        name\n        description\n        discountable\n        code\n    }\n": types.MenuSectionItemFragmentDoc,
    "\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n": types.ServiceItemFragmentDoc,
    "\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n": types.TableItemFragmentDoc,
    "\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n": types.TableGroupItemFragmentDoc,
    "\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n": types.TeamItemFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            name\n            logo\n            category\n            instagramLink\n            facebookLink\n            googleLink\n            tiktokLink\n            imprintOwner\n            imprintOwnerTaxId\n            imprintCompanyName\n            imprintCompanyTaxId\n            imprintPhone\n            imprintEmail\n            settings {\n                currency\n                timezone\n                customerDefaultLanguage\n                notificationEmails\n            }\n        }\n    }\n"): (typeof documents)["\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            name\n            logo\n            category\n            instagramLink\n            facebookLink\n            googleLink\n            tiktokLink\n            imprintOwner\n            imprintOwnerTaxId\n            imprintCompanyName\n            imprintCompanyTaxId\n            imprintPhone\n            imprintEmail\n            settings {\n                currency\n                timezone\n                customerDefaultLanguage\n                notificationEmails\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Companies($offset: Int!, $limit: Int!) {\n        companies(offset: $offset, limit: $limit) {\n            id\n            name\n            category\n            settings {\n                customerDefaultLanguage\n            }\n        }\n    }\n"): (typeof documents)["\n    query Companies($offset: Int!, $limit: Int!) {\n        companies(offset: $offset, limit: $limit) {\n            id\n            name\n            category\n            settings {\n                customerDefaultLanguage\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation preSignPdfUpload {\n    preSignPdfUpload {\n      url\n      path\n    }\n  }\n"): (typeof documents)["\n  mutation preSignPdfUpload {\n    preSignPdfUpload {\n      url\n      path\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation GetPreSignUpload($filename: String!) {\n    preSignImageUpload(filename: $filename) {\n      origin\n      originPath\n      thumbnail\n      thumbnailPath\n    }\n  }\n"): (typeof documents)["\n  mutation GetPreSignUpload($filename: String!) {\n    preSignImageUpload(filename: $filename) {\n      origin\n      originPath\n      thumbnail\n      thumbnailPath\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n      colorCode\n    }\n  }\n"): (typeof documents)["\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n      colorCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n"): (typeof documents)["\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment MenuProductItem on MenuProduct {\n        id\n        code\n        title\n        priceMode\n        description\n        images\n        ingredients\n        labels\n        priceMode\n        discountable\n        allergics\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            id\n            title\n            type\n            values {\n                ...MenuProductConfigurationValueItem\n            }\n        }\n\n        menuId\n        sectionId\n    }\n"): (typeof documents)["\n    fragment MenuProductItem on MenuProduct {\n        id\n        code\n        title\n        priceMode\n        description\n        images\n        ingredients\n        labels\n        priceMode\n        discountable\n        allergics\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            id\n            title\n            type\n            values {\n                ...MenuProductConfigurationValueItem\n            }\n        }\n\n        menuId\n        sectionId\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment MenuSectionItem on MenuSection {\n        id\n        name\n        description\n        discountable\n        code\n    }\n"): (typeof documents)["\n    fragment MenuSectionItem on MenuSection {\n        id\n        name\n        description\n        discountable\n        code\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n"): (typeof documents)["\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n"): (typeof documents)["\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n"): (typeof documents)["\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;