import {
  OnlineOrderBranchSettingItemFragment,
  OnlineOrderDeliveryMode,
  OnlineOrderItemFragment,
  OnlineOrderProductsItemFragment,
} from '@client/graphql/types/graphql';
import Table from 'ui/src/table/table';
import TableHeaderColumn from 'ui/src/table/table-header-column';
import TableRow from 'ui/src/table/table-row';
import TableRowColumn from 'ui/src/table/table-row-cell';
import CurrencyView from 'core/src/utils/currency-view';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { capitalize } from 'lodash';
import { TableCellAlignment } from '@packages/ui/table/table-type.ts';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderProductsFragment } from '@client/graphql/online-order-fragment.ts';
import CDNLink from '@client/page/management/online-order/utils/cdn-link.ts';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import {
  deliveryDiscountTypeUnit,
  pickupDiscountTypeUnit,
} from '@client/page/management/online-order/setting/component/pickup-discount-type-unit.tsx';
import { useTranslate } from '@tolgee/react';

export default function OnlineProductTable(props: {
  order: OnlineOrderItemFragment;
  configuration: OnlineOrderBranchSettingItemFragment;
}) {
  const { order, configuration } = props;

  const products = getFragmentData(OnlineOrderProductsFragment, order.onlineOrderProducts);
  const { t } = useTranslate();
  return (
    <>
      <Table
        data={products}
        build={(product) => <OnlineProductRow key={product.id} product={product} />}
        footer={<OnlineProductTotalRow order={order} configuration={configuration} />}
      >
        <TableHeaderColumn className="w-6 hidden md:table-cell" />
        <TableHeaderColumn>{t('common.table-header-column.products')}</TableHeaderColumn>
        <TableHeaderColumn alignment={TableCellAlignment.Right}>
          {t('common.table-header-column.price')}
        </TableHeaderColumn>
      </Table>
    </>
  );
}

function OnlineProductRow(props: { product: OnlineOrderProductsItemFragment }) {
  const { product } = props;

  const image = (product.menuProduct?.images.length ?? 0) > 0 ? product.menuProduct?.images[0] : null;

  const productPrice = product.configurations.reduce((acc, config) => {
    return acc + config.price;
  }, 0);

  return (
    <>
      <TableRow key={product.id}>
        <TableRowColumn className="hidden md:table-cell">
          {image ? (
            <div className="h-12 w-12 shrink-0">
              <img src={CDNLink(image)} className="h-12 w-12 rounded-md object-cover" alt="product-image" />
            </div>
          ) : (
            <div className="flex border h-12 w-12 shrink items-center justify-center rounded-md bg-gray-200">
              <PhotoIcon className="h-6 w-6 fill-gray-400" />
            </div>
          )}
        </TableRowColumn>

        <TableRowColumn>
          <div className="py-1.5">
            <p className="font-normal	text-sm mb-0.5">
              {product.quantity} x {product.title}
            </p>
            <ul className="text-gray-600 italic text-xs gap-y-0.5">
              {product.configurations.map((config) => (
                <li key={config.id}>
                  {config.title !== '' && `${capitalize(config.title)}: ${config.value.toLowerCase()}`}
                </li>
              ))}
            </ul>
          </div>
        </TableRowColumn>

        <TableRowColumn alignment={TableCellAlignment.Right}>
          <div className="space-x-1">
            <span className="flex-none text-sm text-gray-500">
              <CurrencyView price={productPrice} /> x {product.quantity}
            </span>

            <span className="hidden md:inline-block">=</span>

            <span className="ml-1 block md:inline-block">
              <CurrencyView className="text-base" price={productPrice * product.quantity} />
            </span>
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

function OnlineProductTotalRow(props: {
  order: OnlineOrderItemFragment;
  configuration: OnlineOrderBranchSettingItemFragment;
}) {
  const currency = useCompanyCurrency();
  const { t } = useTranslate();
  return (
    <>
      {props.order.basePrice && (
        <TableRow className="bg-gray-50 h-8">
          <TableRowColumn className="hidden md:table-cell"></TableRowColumn>
          <TableRowColumn className="text-gray-500">{t('online-order.detail.order.subtotal')}</TableRowColumn>
          <TableRowColumn alignment={TableCellAlignment.Right}>
            <CurrencyView className="text-md" price={props.order.basePrice} />
          </TableRowColumn>
        </TableRow>
      )}

      {props.order.deliveryMode === OnlineOrderDeliveryMode.Delivery && props.order.deliveryPrice && (
        <TableRow className="bg-gray-50 h-8">
          <TableRowColumn className="hidden md:table-cell"></TableRowColumn>
          <TableRowColumn className="text-gray-300">{t('online-order.detail.order.delivery-fee')}</TableRowColumn>
          <TableRowColumn alignment={TableCellAlignment.Right}>
            +<CurrencyView className="text-md" price={props.order.deliveryPrice} />
          </TableRowColumn>
        </TableRow>
      )}
      {props.order.deliveryDiscount && (
        <TableRow className="bg-gray-50 h-8">
          <TableRowColumn className="hidden md:table-cell"></TableRowColumn>
          <TableRowColumn className="flex text-gray-500 mt-1">
            Delivery discount (
            <span>
              {deliveryDiscountTypeUnit(
                props.configuration.deliveryDiscountAmount,
                props.configuration.deliveryDiscountType,
                currency
              )}
            </span>
            )
          </TableRowColumn>
          <TableRowColumn alignment={TableCellAlignment.Right}>
            <CurrencyView className="text-md" price={props.order.deliveryDiscount} />
          </TableRowColumn>
        </TableRow>
      )}

      {props.order.pickupDiscount && (
        <TableRow className="bg-gray-50 h-8">
          <TableRowColumn className="hidden md:table-cell"></TableRowColumn>
          <TableRowColumn className="text-gray-500">
            Pickup discount (
            {pickupDiscountTypeUnit(
              props.configuration.pickupDiscountAmount,
              props.configuration.pickupDiscountType,
              currency
            )}
            )
          </TableRowColumn>
          <TableRowColumn alignment={TableCellAlignment.Right}>
            <CurrencyView className="text-md" price={props.order.pickupDiscount} />
          </TableRowColumn>
        </TableRow>
      )}

      <TableRow className="">
        <TableRowColumn className="hidden md:table-cell"></TableRowColumn>
        <TableRowColumn className="text-lg font-semibold">{t('online-order.detail.order.total')}</TableRowColumn>
        <TableRowColumn alignment={TableCellAlignment.Right}>
          <CurrencyView className="text-3xl" price={props.order.totalPrice} />
        </TableRowColumn>
      </TableRow>
    </>
  );
}

// {(product) => <OnlineProductRow key={product.id} product={product} />}
