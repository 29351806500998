import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import useFormData from '@client/module/utils/use-form-data.ts';
import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import { useTranslate } from '@tolgee/react';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import useCheckAdminRole from '@client/module/auth/check-role-hook.ts';
import useDeleteBusinessData from '@client/page/management/admin-config/logic/use-delete-business-data.ts';
import { Features } from '@client/graphql/types/graphql.ts';
import { Checkbox } from '@headlessui/react';

interface DeleteBusinessDataInput {
  startTime: string;
  endTime: string;
  services: Features[];
}

export default function AdminConfig() {
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const { isSuperAdmin } = useCheckAdminRole();

  const [update, { error }] = useDeleteBusinessData();

  const { data, handle, setData, manualHandle } = useFormData<DeleteBusinessDataInput>({
    startTime: '',
    endTime: '',
    services: [Features.Reservation, Features.OnlineOrder],
  });

  const handleDeleteSubmit = () => {
    if (data.startTime == '' || data.endTime == '' || data.services.length == 0) {
      showAlert({
        status: 'error',
        message: t(
          'appointment.configuration.admin.delete-appointment.empty-error.message',
          'Please enter for full field.'
        ),
        title: t('appointment.configuration.admin.delete-appointment.error.title', 'Error'),
      });
      return;
    }
    update({
      variables: {
        branchId: branchId,
        startTime: data.startTime,
        endTime: data.endTime,
        services: data.services,
      },
    })
      .then(() => {
        showAlert({
          status: 'success',
          message: t('appointment.configuration.admin.delete-appointment.success.message', 'Delete successfully!!'),
          title: t('appointment.configuration.admin.delete-appointment.title', 'Success'),
        });
        setData({
          startTime: '',
          endTime: '',
          services: [],
        });
      })
      .catch(() => {
        showAlert({
          status: 'error',
          message: t('appointment.configuration.admin.delete-appointment.error.message', 'Some thing went wrong.'),
          title: t('appointment.configuration.admin.delete-appointment.error.title', 'Error'),
        });
      });
  };

  const applicationErrors = formatGraphQlError(error?.graphQLErrors);
  const validationError = validationErrors(applicationErrors);

  if (!isSuperAdmin) {
    return <>You have not permission</>;
  }

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={t('appointment.configuration.admin.delete-appointment', 'Delete business data')} />

          <CardContent>
            <FormLayout>
              <FormItem title={t('appointment.configuration.admin.delete-history.service.title', 'Services')}>
                <div className="flex flex-col sm:flex-row gap-x-8 gap-y-3">
                  {/*Reservation*/}
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={data.services.includes(Features.Reservation)}
                      onChange={(checked) => {
                        if (checked) {
                          manualHandle('services', [...data.services, Features.Reservation]);
                          return;
                        }
                        const reservationIndex = data.services.findIndex((item) => item == Features.Reservation);
                        if (reservationIndex !== -1)
                          manualHandle('services', [
                            ...data.services.slice(0, reservationIndex),
                            ...data.services.slice(reservationIndex + 1),
                          ]);
                      }}
                      className="group block size-4 rounded border bg-white data-[checked]:bg-blue-500"
                    >
                      {/* Checkmark icon */}
                      <svg
                        className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Checkbox>
                    <p>{t('appointment.configuration.admin.delete-history.reservation', 'Reservation')}</p>
                  </div>

                  {/*Online order*/}
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={data.services.includes(Features.OnlineOrder)}
                      onChange={(checked) => {
                        if (checked) {
                          manualHandle('services', [...data.services, Features.OnlineOrder]);
                          return;
                        }
                        const onlineOrderIndex = data.services.findIndex((item) => item == Features.OnlineOrder);
                        if (onlineOrderIndex !== -1)
                          manualHandle('services', [
                            ...data.services.slice(0, onlineOrderIndex),
                            ...data.services.slice(onlineOrderIndex + 1),
                          ]);
                      }}
                      className="group block size-4 rounded border bg-white data-[checked]:bg-blue-500"
                    >
                      {/* Checkmark icon */}
                      <svg
                        className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Checkbox>
                    <p>{t('appointment.configuration.admin.delete-history.online-order', 'Online order')}</p>
                  </div>

                  {/*Appointment*/}
                  <div className="flex items-center gap-2">
                    <Checkbox
                      checked={data.services.includes(Features.Appointment)}
                      onChange={(checked) => {
                        if (checked) {
                          manualHandle('services', [...data.services, Features.Appointment]);
                          return;
                        }
                        const appointmentIndex = data.services.findIndex((item) => item == Features.Appointment);
                        if (appointmentIndex !== -1)
                          manualHandle('services', [
                            ...data.services.slice(0, appointmentIndex),
                            ...data.services.slice(appointmentIndex + 1),
                          ]);
                      }}
                      className="group block size-4 rounded border bg-white data-[checked]:bg-blue-500"
                    >
                      {/* Checkmark icon */}
                      <svg
                        className="stroke-white opacity-0 group-data-[checked]:opacity-100"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Checkbox>
                    <p>{t('appointment.configuration.admin.delete-history.appointment', 'Appointment')}</p>
                  </div>
                </div>
              </FormItem>
              <FormItem title={t('appointment.configuration.admin.delete-history.start', 'Start time')}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="Start Date"
                  id="startTime"
                  name="startTime"
                  value={data.startTime}
                  type="date"
                  onChange={handle}
                />

                {validationError.startTime && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {validationError.startTime}
                  </p>
                )}
              </FormItem>

              <FormItem title={t('appointment.configuration.admin.delete-history.end', 'End time')}>
                <input
                  className="rounded-md max-w-full border-gray-300"
                  aria-label="End Time"
                  id="endTime"
                  name="endTime"
                  value={data.endTime}
                  type="date"
                  onChange={handle}
                />

                {validationError.endTime && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {validationError.endTime}
                  </p>
                )}
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <PrimaryButton onClick={handleDeleteSubmit}>{t('common-button-submit', 'Submit')}</PrimaryButton>
          </CardFooter>
        </Card>
      </PrimaryContent>
    </ContentLayout>
  );
}
