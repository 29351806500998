import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import Card from 'ui/src/card/card';
import CardHeader from 'ui/src/card/card-header';
import CardTableContent from 'ui/src/card/card-content-table';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderListFragment } from '@client/graphql/online-order-fragment';
import { ContentLayout, FullPrimaryContent } from 'ui/src/content-layout';
import ApplicationErrorView from '@client/module/error/application-error-view';
import OnlineOrderDailySummarize from '@client/page/management/online-order/dashboard/component/online-order-daily-summarize.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from '@tolgee/react';
import { useSearchParams } from 'react-router-dom';
import { OnlineOrderListItemFragment } from '@client/graphql/types/graphql.ts';
import { ChevronLeftIcon } from '@heroicons/react/16/solid';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import HistorySearchBar from '@client/page/management/appointment/history/component/history-search-bar.tsx';
import OnlineOrderHistoryTable from '@client/page/management/online-order/history/component/online-order-history-table.tsx';
import { useFocusedBranchContext } from '../../table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { TableReservationRangeFilter } from '../../table-reservation/list/component/table-reservation-range-filter';
import useOnlineOrdersHistory from './logic/use-online-order-history';

const OnlineOrderHistory = () => {
  const [searchQuery] = useSearchParams();
  const { t } = useTranslate();

  const branchProvider = useFocusedBranchContext();
  const branchID = branchProvider.branch?.id ?? '';

  const initialStartTime = dayjs().startOf('month').startOf('day');
  const initialEndTime = dayjs().endOf('month').endOf('day');

  const [startTime, setStartTime] = useState<Dayjs>(() => {
    if (searchQuery.get('nearestDetailTime')) {
      return dayjs(searchQuery.get('nearestDetailTime')).startOf('month');
    }
    return initialStartTime;
  });
  const [endTime, setEndTime] = useState<Dayjs>(() => {
    if (searchQuery.get('nearestDetailTime')) {
      return dayjs(searchQuery.get('nearestDetailTime')).endOf('month');
    }
    return initialEndTime;
  });

  const { data, loading, error, refetch } = useOnlineOrdersHistory(branchID, {
    startTime: startTime.format('YYYY-MM-DDTHH:mm'),
    endTime: endTime.format('YYYY-MM-DDTHH:mm'),
  });

  const [onlineOrders, setOnlineOrders] = useState<OnlineOrderListItemFragment[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const orders = getFragmentData(OnlineOrderListFragment, data.onlineOrdersHistory);

    setOnlineOrders(orders.filter((item) => !item.deleted));
  }, [data, setOnlineOrders]);

  const handleQuickPick = (type: 'currentMonth' | 'previousMonth' | 'nextMonth') => {
    let newStartTime, newEndTime;
    const today = dayjs();

    switch (type) {
      case 'currentMonth':
        newStartTime = today.startOf('month');
        newEndTime = today.endOf('month');
        break;
      case 'previousMonth':
        newStartTime = startTime.subtract(1, 'month').startOf('month');
        newEndTime = startTime.subtract(1, 'month').endOf('month');
        break;
      case 'nextMonth':
        newStartTime = startTime.add(1, 'month').startOf('month');
        newEndTime = startTime.add(1, 'month').endOf('month');
        break;
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };

  const handleSearchBar = useCallback(
    (searchString: string) => {
      const orders = getFragmentData(OnlineOrderListFragment, data?.onlineOrdersHistory) ?? [];

      setOnlineOrders(
        orders.filter((onlineOrder) => {
          if (onlineOrder.guestName?.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          if (onlineOrder.phone?.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          if (onlineOrder.email?.toLowerCase().includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        })
      );
    },
    [data, setOnlineOrders]
  );

  return (
    <ContentLayout>
      {' '}
      <FullPrimaryContent>
        <ApplicationErrorView className="my-4" error={error} />
        <Card>
          <CardHeader title={t('online-order.history.title')}>
            <div className="flex gap-4">
              <SecondaryButton
                onClick={() => {
                  handleQuickPick('previousMonth');
                }}
                className="flex items-center"
              >
                <ChevronLeftIcon className="hidden sm:block w-4 h-4" />
                {t('common.button.previous-month', 'Previous Month')}
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  handleQuickPick('currentMonth');
                }}
              >
                {t('common.button.current-month')}
              </SecondaryButton>
              <SecondaryButton
                onClick={() => {
                  handleQuickPick('nextMonth');
                }}
                className="flex items-center"
              >
                {t('common.button.next-month', 'Next Month')}
                <ChevronRightIcon className="hidden sm:block w-4 h-4" />
              </SecondaryButton>
            </div>
            <TableReservationRangeFilter
              startTime={startTime}
              endTime={endTime}
              onChange={(dates) => {
                setStartTime(dates[0]);
                setEndTime(dates[1]);
              }}
            />
          </CardHeader>

          <CardTableContent>
            <HistorySearchBar onChange={handleSearchBar} />
            <OnlineOrderDailySummarize order={onlineOrders} />
            <OnlineOrderHistoryTable
              error={error?.message}
              refetch={() => {
                void refetch();
              }}
              loading={loading}
              orders={onlineOrders}
              branchID={branchID}
            />
          </CardTableContent>
        </Card>
      </FullPrimaryContent>
    </ContentLayout>
  );
};

export default OnlineOrderHistory;
