import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { TableReservationConfirmationStatus, TableReservationItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment, ReactElement } from 'react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { useFocusedCompanyContext } from '@packages/core/company/focused-company-context.ts';
import { ReservationTableListConfirmationStatus } from '@client/page/management/table-reservation/list/component/reservation-table-list-confirmation-status.tsx';

export default function TableReservationHistoryTableList(props: {
  loading?: boolean;
  items: TableReservationItemFragment[];
  onClick: (id: string) => void;
  actions?: (ctx: { id: string }) => ReactElement;
  refetch: () => void;
  error?: string;
}) {
  const { items, actions, onClick } = props;
  const { t } = useTranslate();
  const company = useFocusedCompanyContext();

  if (items.length === 0 && props.loading == false) {
    return <div className="px-6 py-4">{t('reservation.no-reservation')}</div>;
  }

  // Group by date
  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(item.timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, reservations]) => {
    const sortedReservations = Array.from(reservations);
    sortedReservations.sort((a, b) => {
      return moment(a.startTime).isBefore(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      reservations: sortedReservations,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    if (!a.reservations[0] || !b.reservations[0]) {
      return -1;
    }
    return moment(a.reservations[0].startTime).isBefore(moment(b.reservations[0].startTime)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        error={props.error}
        refetch={props.refetch}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-blue-500">
                  {item.date == moment().tz(company.settings.timezone).format('DD MMM')
                    ? t('reservation.history.list.section.today', 'Today')
                    : item.date}
                </span>
              </div>
            </TableSection>

            {item.reservations.map((reservation) => (
              <ReservationRow
                key={reservation.id}
                item={reservation}
                actions={actions}
                onClick={() => {
                  onClick(reservation.id);
                }}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        {/*<TableHeaderColumn className="max-sm:hidden">*/}
        {/*  Platform*/}
        {/*</TableHeaderColumn>*/}
        <TableHeaderColumn className="w-12">{t('common.table-header-column.status')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.time')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.guest')}</TableHeaderColumn>
        <TableHeaderColumn className="max-sm:hidden">
          {t('common.table-header-column.number-of-guests')}
        </TableHeaderColumn>
        <TableHeaderColumn className="max-sm:hidden">{t('common.table-header-column.message')}</TableHeaderColumn>
        <TableHeaderColumn />
      </Table>
    </>
  );
}

function ReservationRow(props: {
  item: TableReservationItemFragment;
  actions?: (ctx: { id: string }) => ReactElement;
  onClick: () => void;
}) {
  const { item, actions, onClick } = props;

  return (
    <>
      <TableRow key={item.id} onClick={onClick} className="cursor-pointer">
        {/* Platform */}
        {/*<TableRowColumn className="max-sm:hidden">*/}
        {/*  {item.platformType}*/}
        {/*</TableRowColumn>*/}

        <TableRowColumn>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <ReservationTableListConfirmationStatus item={item} />
          </div>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="font-bold">
          {tableReservationTimeFormat(item.startTime, item.timezone)}
        </TableRowColumn>

        {/* Name */}
        <TableRowColumn className="md:max-w-96">
          <span>{item.guestName}</span>
          <span className="md:hidden"> ({item.seats})</span>
        </TableRowColumn>

        {/* Seats */}
        <TableRowColumn className="max-sm:hidden">{item.seats}</TableRowColumn>

        {/* Message */}
        <TableRowColumn className="max-sm:hidden md:max-w-96">{item.message}</TableRowColumn>

        {/* Action */}
        <TableRowColumn className="space-x-4">
          <div className="flex items-center justify-end space-x-1">
            <div className="max-sm:hidden">{actions?.({ id: item.id })}</div>
            <ChatBubbleOvalLeftEllipsisIcon
              className={classNames('fill-yellow-500 w-4 h-4 md:hidden', {
                hidden: item.message.length === 0,
              })}
            />
            <ChevronRightIcon className="w-7 fill-gray-400" />
          </div>
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export function TableReservationIcon(props: { status: TableReservationConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case TableReservationConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case TableReservationConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case TableReservationConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}

function tableReservationTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
