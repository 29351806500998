import { useState, useEffect } from 'react';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import { GeneralDeleteButton } from '@client/page/component/general-delete-button.tsx';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';
import { useUpdateCompanySettings } from '@client/page/management/company-settings/logic/use-update-company-settings.ts';
import useUserCompanyRoles from '@client/page/user-company-role/user-list/logic/use-user-company-roles.ts';
import { captureException } from '@sentry/browser';
import { useTranslate } from '@tolgee/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';

interface ExtraEmail {
  email: string;
  error: string;
  isEditing: boolean;
  tempEmail: string;
}

export default function EmailManager() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const { data: companySettingData, refetch } = useCompanySettings(companyID);
  const [updateCompanySetting] = useUpdateCompanySettings();
  const { showAlert } = useNotificationCenter();

  const [extraEmails, setExtraEmails] = useState<ExtraEmail[]>([]);
  const [newEmail, setNewEmail] = useState('');
  const [newEmailError, setNewEmailError] = useState('');

  const { data: userList } = useUserCompanyRoles(companyID, 0, 999);

  // Validate
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const mailList = userList?.userCompanyRoles.map((item) => item.user.email) ?? [];
  const branchMail = companySettingData?.company?.branch?.map((branch) => branch.contactEmail) ?? '';

  const validateEmail = (email: string | undefined, isExistingList = false, currentIndex?: number) => {
    if (!email) {
      return t('company-setting.extra-email.error.empty', 'Email is empty');
    }

    if (!email.trim()) {
      return t('company-setting.extra-email.error.trim');
    }

    if (!emailRegex.test(email)) {
      return t('company-setting.extra-email.error.invalid');
    }

    if (isExistingList && (mailList.includes(email) || branchMail.includes(email))) {
      return t('company-setting.extra-email.error.exists');
    }

    // Modified duplicate check
    if (
      isExistingList &&
      extraEmails.some((e, index) => e.email === email && (currentIndex === undefined || index !== currentIndex))
    ) {
      return t('company-setting.extra-email.error.duplicate');
    }

    return '';
  };

  // Load initial emails from company settings
  useEffect(() => {
    setExtraEmails(
      companySettingData?.company?.settings.notificationEmails.map((email) => ({
        email,
        error: '',
        isEditing: false,
        tempEmail: email,
      })) ?? []
    );
  }, [companySettingData?.company?.settings.notificationEmails]);

  // Function to update extra emails in the database
  const updateExtraEmails = async (emails: ExtraEmail[]) => {
    await updateCompanySetting({
      variables: {
        id: companySettingData?.company?.settings.id ?? '',
        input: {
          notificationEmails: emails.map((e) => e.email),
          currency: companySettingData?.company?.settings.currency ?? '',
          customerDefaultLanguage: companySettingData?.company?.settings.customerDefaultLanguage ?? 'de',
        },
      },
    });
  };

  // Add a new email and update the database
  const addEmail = async () => {
    const error = validateEmail(newEmail, true);

    if (error) {
      setNewEmailError(error);
      return;
    }

    const updatedEmails = [
      ...extraEmails,
      {
        email: newEmail,
        error: '',
        isEditing: false,
        tempEmail: newEmail,
      },
    ];
    setExtraEmails(updatedEmails);
    setNewEmail('');
    setNewEmailError('');
    await updateExtraEmails(updatedEmails);
  };

  // Start editing an email
  const startEditEmail = (index: number) => {
    const updatedEmails = extraEmails.map((email, i) => ({
      ...email,
      isEditing: i === index,
      tempEmail: email.email,
      error: '',
    }));
    setExtraEmails(updatedEmails);
  };

  // Cancel editing an email
  const cancelEditEmail = (index: number) => {
    const updatedEmails = [...extraEmails];
    let updatedEmail = updatedEmails.at(index);

    if (!updatedEmail) {
      showAlert({
        title: t('cancel-email.error.title', 'Error'),
        message: t('cancel-email.error.message', 'Cancel edit error'),
        status: 'error',
      });
      return;
    }

    updatedEmail = {
      ...updatedEmail,
      isEditing: false,
      tempEmail: updatedEmail.email,
      error: '',
    };

    setExtraEmails(updatedEmails);
  };

  // Update an existing email
  const updateEmail = async (index: number) => {
    const currentEmail = extraEmails.at(index);

    if (!currentEmail) {
      showAlert({
        title: t('cancel-email.error.title', 'Error'),
        message: t('cancel-email.error.message', 'Not exist'),
        status: 'error',
      });
      return;
    }

    // Validate the new email
    const error = validateEmail(currentEmail.tempEmail, true, index);

    if (error) {
      const updatedEmails = [...extraEmails];
      let updatedEmail = updatedEmails.at(index);

      if (!updatedEmail) {
        showAlert({
          title: t('cancel-email.error.title', 'Error'),
          message: t('cancel-email.error.message', 'Update email error'),
          status: 'error',
        });
        return;
      }

      updatedEmail = {
        ...updatedEmail,
        error,
      };
      setExtraEmails(updatedEmails);
      return;
    }

    // Create updated emails array
    const updatedEmails = [...extraEmails];
    updatedEmails[index] = {
      email: currentEmail.tempEmail,
      error: '',
      isEditing: false,
      tempEmail: currentEmail.tempEmail,
    };

    // Update state and database
    setExtraEmails(updatedEmails);
    await updateExtraEmails(updatedEmails);
  };

  const deleteEmail = async (index: number) => {
    const updatedEmails = [...extraEmails];
    updatedEmails.splice(index, 1);
    setExtraEmails(updatedEmails);
    await updateExtraEmails(updatedEmails);
  };

  return (
    <Card>
      <CardHeader title={t('company-setting.extra-email.header')} />
      <CardContent>
        <div className="space-y-4">
          {/* Add New Email Section */}
          <div className="flex flex-col gap-1">
            <div className="flex gap-3">
              <TextInput
                className="w-full"
                type="text"
                placeholder={t('company-setting.extra-email.placeholder')}
                value={newEmail}
                onChange={(e) => {
                  setNewEmail(e.target.value);
                  setNewEmailError('');
                }}
                label={''}
                name={''}
                error={newEmailError || undefined}
              />
              <PrimaryButton
                className="h-9"
                onClick={() => {
                  addEmail().catch(captureException);
                }}
              >
                <PlusIcon className="w-5 h-5" />
                {t('company-setting.extra-email.add')}
              </PrimaryButton>
            </div>
          </div>

          <hr />

          {/* Existing Emails List */}
          {extraEmails.map((email, index) => (
            <div key={index} className="flex flex-col gap-1 mb-2">
              <div className="flex gap-2">
                <TextInput
                  className="w-full"
                  type="text"
                  value={email.isEditing ? email.tempEmail : email.email}
                  onChange={(e) => {
                    if (email.isEditing) {
                      const updatedEmails = [...extraEmails];

                      let updatedEmail = updatedEmails.at(index);

                      if (!updatedEmail) {
                        showAlert({
                          title: t('cancel-email.error.title', 'Error'),
                          message: t('cancel-email.error.message', 'Update email error'),
                          status: 'error',
                        });
                        return;
                      }

                      updatedEmail = {
                        ...updatedEmail,
                        tempEmail: e.target.value,
                        error: '',
                      };
                      setExtraEmails(updatedEmails);
                    }
                  }}
                  label={''}
                  name={''}
                  placeholder={'Enter email'}
                  error={email.error || undefined}
                />
                {!email.isEditing ? (
                  <div className="flex gap-2">
                    <PrimaryButton
                      className="h-9"
                      onClick={() => {
                        startEditEmail(index);
                      }}
                    >
                      {t('company-setting.extra-email.edit')}
                    </PrimaryButton>
                    <GeneralDeleteButton
                      className="h-9"
                      stayNavigate={true}
                      alert={{
                        label: t('company-setting.extra-email.delete.confirm.label', 'Delete'),
                        title: t('company-setting.extra-email.delete.confirm.title', 'Delete extra email'),
                        message: t(
                          'company-setting.extra-email.delete.confirm.message',
                          'Are you sure you want to delete this extra email?'
                        ),
                      }}
                      delete={() => deleteEmail(index)}
                      refetch={() => {
                        refetch().catch(captureException);
                      }}
                    />
                  </div>
                ) : (
                  <div className="flex gap-2">
                    <PrimaryButton
                      className="h-9"
                      onClick={() => {
                        updateEmail(index).catch(captureException);
                      }}
                    >
                      {t('company-setting.extra-email.update', 'Update')}
                    </PrimaryButton>
                    <button
                      className="rounded-md bg-red-500 px-2.5 h-9 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-700"
                      onClick={() => {
                        cancelEditEmail(index);
                      }}
                    >
                      {t('common.cancel')}
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}
