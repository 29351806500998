import { useQuery } from '@apollo/client';
import { graphql } from '@client/graphql/types';

const QUERY = graphql(`
  query UserCompanyRoles($companyID: ID!, $offset: Int!, $limit: Int!) {
    userCompanyRoles(companyID: $companyID, offset: $offset, limit: $limit) {
      user {
        id
        firstName
        lastName
        username
        role
        email
        status
        colorCode
      }
      permission
    }
  }
`);

export default function useUserCompanyRoles(companyId: string, offset: number, limit: number) {
  return useQuery(QUERY, {
    variables: {
      companyID: companyId,
      offset: offset,
      limit: limit,
    },
  });
}
