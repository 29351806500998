import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { ChartOptions, type ChartData } from 'chart.js';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import useReservationConfirmationStatusRate
  from '@client/page/management/table-reservation/analytic-new/logic/use-reservation-confirmation-status-rate.ts';
import { Dayjs } from 'dayjs';
import {
  useFocusedBranchContext
} from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';

export default function ReservationGroupByConfirmationStatus(
  props: {
    startTime: Dayjs;
    endTime: Dayjs;
  }
) {
  const { branch } = useFocusedBranchContext();

  const { data } = useReservationConfirmationStatusRate(
    branch?.id,
    props.startTime.format(),
    props.endTime.format(),
  );

  const [chartData, setChartData] = useState<ChartData<'doughnut'>>({ labels: [], datasets: [] });

  useEffect(() => {
    if (!data) {
      return;
    }

    const aggregateData = data.analytic_reservationsByConfirmationStatus;

    // One day
    const labels = aggregateData.map((item) =>
      item.status.toLowerCase(),
    );

    const everydayTrends = aggregateData.map(
      (item) => item.count,
    );

    setChartData({
      labels: labels,
      datasets: [
        {
          label: 'Orders',
          data: everydayTrends,
          borderWidth: 2,
          borderRadius: 4,
          backgroundColor: aggregateData.map((value) => {
            switch (value.status) {
              case 'CONFIRMED':
                return 'rgba(74,250,76,0.5)';
              case 'PENDING':
                return 'rgba(255,198,0,0.5)';
              case 'CANCELLED':
                return 'rgba(255,75,75,0.5)';
              default:
                return 'rgba(255,198,0,0.5)';
            }
          }),
        },
      ],
    });
  }, [data, setChartData]);

  const options: ChartOptions<'doughnut'> = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  return (
    <Card className="">
      <CardHeader title="Confirmation ration" />
      <CardContent>
        <Doughnut options={options} data={chartData} />
      </CardContent>
    </Card>
  );
}
