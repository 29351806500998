import { RouteObject } from 'react-router-dom';
import TableReservationBranchOverview
  from '@client/page/management/table-reservation/branch-overview/table-reservation-branch-overview.tsx';
import {
  TableReservationRangeFilter,
} from '@client/page/management/table-reservation/list/component/table-reservation-range-filter.tsx';
import dayjs from 'dayjs';
import TableReservationHistory from '@client/page/management/table-reservation/list/table-reservation-history.tsx';
import { OpeningTimeRouter } from '@client/page/management/opening-time/opening-time.router.tsx';
import TableReservationSinglePage
  from '@client/page/management/table-reservation/list/table-reservation-single-page.tsx';
import TableReservationSettingGeneral
  from '@client/page/management/table-reservation/setting/table-reservation-setting-general.tsx';
import TableReservationSettingAdvanced
  from '@client/page/management/table-reservation/setting/table-reservation-setting-advanced.tsx';
import TableReservationSettingNotification
  from '@client/page/management/table-reservation/setting/table-reservation-setting-notification.tsx';
import TableReservationSettingTime
  from '@client/page/management/table-reservation/setting/table-reservation-setting-time.tsx';
import TableReservationAnalyticNew
  from '@client/page/management/table-reservation/analytic-new/table-reservation-analytic.tsx';
import TableReservationAnalytic
  from '@client/page/management/table-reservation/analytic/table-reservation-analytic.tsx';
import TableReservationCreate from './create/table-reservation-create';
import TableReservationDetail from './detail/table-reservation-detail';

export const TableReservationRouter: RouteObject = {
  path: 'table-reservation',
  handle: {
    crumb: () => 'breadcumb.reservation.title',
  },
  children: [
    {
      path: '',
      element: <TableReservationBranchOverview />,
    },
    {
      path: 'list',
      handle: {
        crumb: () => 'breadcumb.reservation.schedule',
      },
      children: [
        {
          path: '',
          element: <TableReservationSinglePage />,
        },
        {
          path: ':id',
          element: <TableReservationDetail />,
          handle: {
            crumb: () => 'breadcumb.reservation.edit',
          },
        },
        {
          path: 'create',
          element: <TableReservationCreate />,
          handle: {
            crumb: () => 'breadcumb.reservation.create',
          },
        },
      ],
    },
    {
      path: 'analytics',
      element: <TableReservationAnalytic />,
    },
    {
      path: 'history',
      handle: {
        crumb: () => 'breadcumb.reservation.history',
      },
      children: [
        {
          path: '',
          element: (
            <TableReservationHistory
              key="history"
              initialStartTime={dayjs().startOf('month').startOf('day')}
              initialEndTime={dayjs().endOf('month').endOf('day')}
              filter={(data) => (
                <TableReservationRangeFilter
                  startTime={data.startTime}
                  endTime={data.endTime}
                  onChange={data.onChange}
                />
              )}
            />
          ),
        },
        {
          path: ':id',
          element: <TableReservationDetail />,
        },
      ],
    },
    {
      path: 'new-analytics',
      handle: {
        crumb: () => 'breadcumb.reservation.analytics',
      },
      element: <TableReservationAnalyticNew />,
    },
    {
      path: 'preferences',
      handle: {
        crumb: () => 'breadcumb.reservation.setting',
      },
      children: [
        OpeningTimeRouter,
        {
          path: 'general',
          handle: {
            crumb: () => 'breadcumb.reservation.setting.general',
          },
          element: <TableReservationSettingGeneral />,
        },
        {
          path: 'advanced',
          handle: {
            crumb: () => 'breadcumb.reservation.setting.advanced',
          },
          element: <TableReservationSettingAdvanced />,
        },
        {
          path: 'notification',
          handle: {
            crumb: () => 'breadcumb.reservation.setting.notification',
          },
          element: <TableReservationSettingNotification />,
        },
        {
          path: 'opening-time',
          handle: {
            crumb: () => 'breadcumb.reservation.setting.opening-time',
          },
          element: <TableReservationSettingTime />,
        },
      ],
    },
  ],
};
