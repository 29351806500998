import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { PickedProductInput } from '@client/page/management/appointment/create/util/appointment-create-model.ts';
import useAppointmentBranchConfiguration from '@client/page/management/appointment/configuration/logic/use-appointment-branch-configuration.ts';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentConfigurationFragment } from '@client/graphql/nail-appointment-configuration-fragment.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { formatCurrency } from '@packages/core/utils/currency-formatter.ts';
import { useEffect } from 'react';

interface PickedProductWithName {
  productName: string;
  price: number;
  productID: string;
  pickedConfiguration: { valueID: string; configurationID: string }[];
}

export default function ServiceSelection(props: {
  services: PickedProductInput[] | undefined;
  setService: (service: PickedProductInput) => void;
  setDisableSubmit?: (disable: boolean) => void;
}) {
  const currency = useCompanyCurrency();
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { data: configData } = useAppointmentBranchConfiguration(branchId);

  useEffect(() => {
    if (!props.setDisableSubmit) {
      return;
    }

    if (updateServices.length == 0) {
      props.setDisableSubmit(true);
      return;
    }

    props.setDisableSubmit(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.setDisableSubmit, configData]);

  const appointmentBranchConfiguration = getFragmentData(
    NailAppointmentConfigurationFragment,
    configData?.appointmentBranchConfiguration
  );

  const updateServices: PickedProductWithName[] =
    appointmentBranchConfiguration?.menu?.menuProducts.map((item) => ({
      productID: item.id,
      productName: item.title,
      price: item.configurations[0]?.values[0]?.price ?? 0,
      pickedConfiguration: [
        {
          configurationID: item.configurations[0]?.id ?? '',
          valueID: item.configurations[0]?.values[0]?.id ?? '',
        },
      ],
    })) ?? [];

  const currentService = props.services?.[0]
    ? (updateServices.find((service) => service.productID == props.services?.at(0)?.productID) ?? null)
    : null;

  const handleClick = (service: PickedProductWithName | undefined) => {
    if (!service) return;
    props.setService({
      productID: service.productID,
      pickedConfiguration: service.pickedConfiguration,
    });
  };

  return (
    <>
      {updateServices.length !== 0 ? (
        <MenuSelectionInput
          title=""
          data={updateServices}
          value={currentService}
          onChange={(item) => {
            handleClick(item);
          }}
          build={(item: PickedProductWithName) => {
            return {
              id: item.productID,
              name: item.productName,
              suffix: formatCurrency(item.price, currency),
            };
          }}
        />
      ) : (
        <p className="text-red-600 -mt-0.5 text-sm pl-0.5">Have no service to select</p>
      )}
    </>
  );
}
