import { useNavigate } from 'react-router-dom';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useCallback } from 'react';
import CardSection from '@packages/ui/card/card-section.tsx';
import DestroyButton from '@packages/ui/button/destroy-button.tsx';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';

interface Props {
  disabled?: boolean;
  className?: string;
  alert: {
    label?: string;
    title: string;
    message: string;
  };
  card?: {
    title: string;
    descriptions: string;
    buttonTitle: string;
  };
  stayNavigate?: boolean;
  delete: () => Promise<void>;
  refetch?: () => void;
}

export function GeneralDeleteButton(props: Props) {
  const navigate = useNavigate();
  const dialog = useDialog();
  const { t } = useTranslate();

  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: props.alert.title,
      message: props.alert.message,
      textButton: t('common.delete'),
      onConfirm: async () => {
        await props.delete();
        if (!props.stayNavigate) {
          navigate('..');
        }
      },
    });
  }, [dialog, navigate, props, t]);

  return (
    <div className="">
      {props.card ? (
        <CardSection title={props.card.title} descriptions={props.card.descriptions}>
          <DestroyButton
            disabled={props.disabled ?? false}
            className={classNames(props.className)}
            onClick={onDestroy}>{props.card.buttonTitle}</DestroyButton>
        </CardSection>
      ) : (
        <DestroyButton
          disabled={props.disabled ?? false}
          className={classNames(props.className)}
          onClick={onDestroy}>{props.alert.label ?? 'Button'}</DestroyButton>
      )}
    </div>
  );
}
