import useGetVirtualUsers from '@client/page/management/appointment/create/logic/use-get-virtual-users.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { useTranslate } from '@tolgee/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Spinner from '@packages/ui/spinner.tsx';
import { useCallback, useEffect, useState } from 'react';

interface UserCompanyRole {
  id: string;
  user: {
    id: string;
    firstName: string;
    colorCode: string;
  };
}

export default function VirtualUserSelection(props: {
  currentUserId: string;
  setCurrentUserId: (userId: string) => void;
}) {
  const companyId = useCompanyId();
  const { t } = useTranslate();
  const { data, loading } = useGetVirtualUsers(companyId);
  const [userData, setUserData] = useState<UserCompanyRole[]>([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    if (!data) {
      return;
    }

    setUserData(
      data.simpleCompanyUsers.map((item) => ({
        id: item.id,
        user: {
          id: item.user.id,
          firstName: item.user.firstName,
          colorCode: item.user.colorCode,
        },
      }))
    );
  }, [data]);

  const handleSearchBar = useCallback(
    (searchString: string) => {
      if (!data) {
        return;
      }

      setSearchString(searchString);

      setUserData(
        data.simpleCompanyUsers.filter((user) => {
          if (user.user.firstName.toLowerCase().includes(searchString.toLowerCase().trim())) {
            return true;
          }
          return false;
        })
      );
    },
    [data, setUserData]
  );

  if (loading) {
    return <Spinner />;
  }

  if (!data) {
    return <>Empty user data</>;
  }

  const currentUserName =
    data.simpleCompanyUsers.find((user) => user.user.id == props.currentUserId)?.user.firstName ?? '';

  return (
    <div className="relative">
      <Menu>
        <MenuButton
          className="inline-flex border border-1 border-gray-300  w-full sm:max-w-xl text-gray-700 items-center gap-2 rounded-md py-1.5 px-2 text-sm
          shadow-inner shadow-white/10 justify-between"
          onKeyDown={(e) => {
            if (e.key === ' ') {
              // Prevent the dropdown from closing when Space is pressed
              e.preventDefault();
            }
          }}
        >
          {props.currentUserId == '' ? t('appointment.appointment-create.user.not-selected','Not selected') : currentUserName}
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </MenuButton>

        <MenuItems
          transition
          className="w-full sm:max-w-xl bg-white origin-top-right rounded-md border border-1 border-gray-300 mt-1 shadow-md text-sm/6 text-white transition
          duration-100 ease-out [--anchor-gap:var(--spacing-1)] z-50 p-0.5 absolute"
        >
          <div className="text-gray-700 border-b border-1 border-gray-300">
            <input
              type="search"
              value={searchString}
              name="search"
              className="w-full rounded-md border-none border-transparent focus:border-transparent focus:ring-0 text-sm"
              placeholder={t('appointment.appointment-create.user.search.placeholder', 'Search')}
              onChange={(e) => {
                handleSearchBar(e.target.value);
              }}
            />
          </div>
          <div className="max-h-56 overflow-y-scroll no-scrollbar">
            <MenuItem>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  props.setCurrentUserId('');
                }}
                className={`group flex w-full items-center gap-2  py-2 px-1 hover:bg-slate-100`}
              >
                <p className={`text-gray-700 border-l-4 px-2 cursor-pointer`}>
                  {t('appointment.appointment-create.user.remove-selection', 'Remove selection')}
                </p>
              </div>
            </MenuItem>
            {userData.map((user) => {
              const colorCode = user.user.colorCode;
              return (
                <MenuItem key={user.id}>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      props.setCurrentUserId(user.user.id);
                    }}
                    className={`group flex w-full items-center gap-2  py-2 px-1 hover:bg-slate-100`}
                  >
                    <p
                      className={`text-gray-700 border-l-4 px-2 cursor-pointer`}
                      style={{ borderLeftColor: colorCode }}
                    >
                      {user.user.firstName}
                    </p>
                  </div>
                </MenuItem>
              );
            })}
          </div>
        </MenuItems>
      </Menu>
    </div>
  );
}
