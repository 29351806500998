import {
  OnlineOrderConfirmationState,
  OnlineOrderItemFragment,
  OnlineOrderState,
  OnlineOrderStateEventItemFragment,
} from '@client/graphql/types/graphql.ts';
import useFormData from '@client/module/utils/use-form-data.ts';
import Card from '@packages/ui/card/card.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import CardHeader from '@packages/ui/card/card-header.tsx';
import DescriptionItem from '@packages/ui/description/description-item.tsx';
import moment from 'moment-timezone';
import { getFragmentData } from '@client/graphql/types';
import { OnlineOrderStateEventFragment } from '@client/graphql/online-order-fragment.ts';
import OnlineOrderActionConfirm from '@client/page/management/online-order/detail/component/online-order-action-confirm.tsx';
import OnlineOrderActionDecline from '@client/page/management/online-order/detail/component/online-order-action-decline.tsx';
import OnlineOrderActionCancel from '@client/page/management/online-order/detail/component/online-order-action-cancel.tsx';
import { capitalize } from 'lodash';
import OnlineOrderActionDone from '@client/page/management/online-order/detail/component/online-order-action-done.tsx';
import CurrencyView from '@packages/core/utils/currency-view.tsx';
import { useTranslate } from '@tolgee/react';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';

const preparingTimeBlock = [10, 15, 20, 30, 40, 45, 60, 90, 105, 120];

interface Form {
  preparingTime?: number | null;
  deliveryFee: number;
}

const OnlineOrderAction = (props: { order: OnlineOrderItemFragment; compact?: boolean; onClose?: () => void }) => {
  const compact = props.compact ?? false;
  const { t } = useTranslate();

  const { order, onClose } = props;
  // Hooks
  // const currency = useCompanyCurrency();
  // const [updateDeliveryPrice] = useChangeDeliveryPrice();
  // const { showAlert } = useNotificationCenter();
  const { data, manualHandle } = useFormData<Form>({
    preparingTime: order.estimatedDuration ?? 0,
    deliveryFee: order.deliveryPrice ?? 0,
  });

  const events = order.events.map((e) => getFragmentData(OnlineOrderStateEventFragment, e));

  // const handleDeliveryPrice = useCallback(
  //   (deliveryFee: number) => {
  //     updateDeliveryPrice({
  //       variables: {
  //         id: order.id,
  //         deliveryPrice: deliveryFee,
  //       },
  //     })
  //       .then(() => {
  //         showAlert({
  //           status: 'success',
  //           title: 'Success',
  //           message: 'Your online order delivery fee is update',
  //         });
  //       })
  //       .catch((e: Error) => {
  //         showAlert({
  //           status: 'error',
  //           title: 'Error',
  //           message: e.message,
  //         });
  //       });
  //   },
  //   [order.id, showAlert, updateDeliveryPrice]
  // );

  const display = (
    <>
      {!compact && (
        <DescriptionItem title={t('online-order.dashboard.order-status.dialog.amount')}>
          <CurrencyView price={order.totalPrice} />
        </DescriptionItem>
      )}
      <DescriptionItem title={t('online-order.dashboard.order-status.dialog.order-time', 'Order time')}>
        <PreparingTime events={events} order={order} />
      </DescriptionItem>
      <DescriptionItem
        title={t('online-order.dashboard.order-status.dialog.estimate-time', 'Estimated completion time')}
      >
        <EstimatedCompletionTime events={events} preparingTime={data.preparingTime} order={order} />
      </DescriptionItem>
    </>
  );

  const form = (
    <FormLayout>
      <FormItem title={t('online-order.dashboard.order-status.dialog.prepare-time-in-minute')} className="xl:max-w-md">
        <MenuSelectionInput
          title={''}
          data={preparingTimeBlock}
          value={data.preparingTime ?? 30}
          onChange={(value) => {
            if (order.confirmationState === OnlineOrderConfirmationState.Pending) {
              manualHandle('preparingTime', value);
            }
          }}
          build={(item) => {
            return {
              id: item.toString(),
              name: item.toString(),
            };
          }}
          className="max-w-sm mb-2"
        />
      </FormItem>
    </FormLayout>
  );

  const pendingActions = (
    <>
      <OnlineOrderActionDecline orderID={order.id} onClose={props.onClose} />
      <div className="flex-grow" />
      <OnlineOrderActionConfirm orderID={order.id} preparingTime={data.preparingTime} onClose={props.onClose} />
    </>
  );

  const inProgressActions = (
    <>
      <OnlineOrderActionCancel orderID={order.id} onClose={props.onClose} />
      <div className="flex-grow" />
      <OnlineOrderActionDone orderID={order.id} onClose={props.onClose} />
    </>
  );

  return (
    <Card>
      <CardHeader
        title={t('online-order.dashboard.order-status.dialog.title')}
        onCrossButton={
          onClose &&
          (() => {
            onClose();
          })
        }
      ></CardHeader>
      <CardContent>
        {!compact && (
          <div className="bg-gray-50 px-3 py-1.5 rounded-lg flex items-start mb-4 border border-gray-100 shadow-sm">
            <DescriptionItem title={t('online-order.dashboard.order-status.dialog.status')}>
              {capitalize(order.state.replace(/_/g, ' '))}
            </DescriptionItem>
          </div>
        )}
        {order.confirmationState === OnlineOrderConfirmationState.Pending && !order.expectedTime ? form : display}
      </CardContent>

      {order.state !== OnlineOrderState.Done && order.state !== OnlineOrderState.Canceled && (
        <CardFooter>
          {order.confirmationState === OnlineOrderConfirmationState.Pending && pendingActions}
          {order.state === OnlineOrderState.InProgress && inProgressActions}
        </CardFooter>
      )}
    </Card>
  );
};

const PreparingTime = (props: {
  events: readonly OnlineOrderStateEventItemFragment[];
  order: OnlineOrderItemFragment;
}) => {
  const { settings } = useFocusedCompany();

  if (!props.order.expectedTime) {
    const waitingForConfirmationEvent = props.events.find((event) => event.state === OnlineOrderState.InProgress);

    if (waitingForConfirmationEvent) {
      return <>{moment(waitingForConfirmationEvent.timestamp).tz(settings.timezone).format('HH:mm')}</>;
    }

    return;
  }

  return moment(props.order.expectedTime).tz(settings.timezone).format('HH:mm');
};

const EstimatedCompletionTime = (props: {
  order: OnlineOrderItemFragment;
  events: readonly OnlineOrderStateEventItemFragment[];
  preparingTime?: number | null;
}) => {
  const { settings } = useFocusedCompany();

  if (props.preparingTime == null) {
    return null;
  }

  if (!props.order.expectedTime) {
    const inProgressEvent = props.events.find((event) => event.state === OnlineOrderState.InProgress);

    if (inProgressEvent) {
      return (
        <>
          {moment(inProgressEvent.timestamp).add(props.preparingTime, 'minutes').tz(settings.timezone).format('HH:mm')}
        </>
      );
    }
    return;
  }

  return (
    <>{moment(props.order.expectedTime).add(props.preparingTime, 'minutes').tz(settings.timezone).format('HH:mm')}</>
  );
};

export default OnlineOrderAction;
