import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from 'ui/src/card/card-content';
import FormLayout from 'ui/src/form/form-layout';
import React, { useEffect, useState } from 'react';
import { useCompanyId } from 'core/src/company/focused-company-context';
import CardFooter from 'ui/src/card/card-footer';
import PrimaryButton from 'ui/src/button/primary-button';
import { useCompanyService } from '@packages/core/company/company-context.ts';
import { useTranslate } from '@tolgee/react';
import FormItem from 'ui/src/form/form-item';
import TextInput from 'ui/src/form/input/text-input';
import { client } from '@client/graphql/client.ts';
import ImageInput from 'core/src/uploading/image-input';
import useFormData from '@client/module/utils/use-form-data';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { CompanyCategory } from '@client/graphql/types/graphql.ts';
import { useAuth } from '@client/module/auth/auth-context.ts';
import { captureException } from '@sentry/browser';
import { useCompanySettings } from './logic/use-company-setting';
import { useUpdateCompany } from './logic/use-update-company-profile';

interface CompanyUpdateInput {
  name: string;
  logo: string | null;
  category: CompanyCategory[];
}

export default function CompanyBasicSettings() {
  const { t } = useTranslate();
  const companyID = useCompanyId();
  const companyService = useCompanyService();
  const notificationCenter = useNotificationCenter();

  const { data } = useCompanySettings(companyID);
  const [updateCompanyProfile] = useUpdateCompany();
  const { auth } = useAuth();

  // Add a state for validation error
  const [nameError, setNameError] = useState<string | null>(null);

  const {
    data: form,
    handle,
    setData,
    manualHandle,
  } = useFormData<CompanyUpdateInput>({
    name: '',
    logo: null,
    category: [],
  });

  useEffect(() => {
    setData({
      name: companyService.company?.name ?? '',
      logo: companyService.company?.logo ?? null,
      category: companyService.company?.category ?? [],
    });
  }, [
    companyService.company?.logo,
    companyService.company?.name,
    companyService.company?.category,
    data,
    setData,
  ]);

  // Validation function for company name
  const validateCompanyName = (name: string): boolean => {
    const trimmedName = name.trim();

    // Check length
    if (trimmedName.length < 2) {
      setNameError(t('company.validation.name-too-short', 'Company name must be at least 2 characters long'));
      return false;
    }

    if (trimmedName.length > 50) {
      setNameError(t('company.validation.name-too-long', 'Company name cannot exceed 50 characters'));
      return false;
    }

    // Clear any previous errors if validation passes
    setNameError(null);
    return true;
  };

  const onUpdateHandler = () => {
    // Validate before submitting
    if (!validateCompanyName(form.name)) {
      return;
    }

    updateCompanyProfile({
      variables: {
        companyID: companyService.companyID ?? '',
        input: {
          name: form.name.trim(), // Trim whitespace before sending
          logo: form.logo,
          category: form.category,
        },
      },
    })
      .then(() => {
        location.reload();
      })
      .catch((e) => {
        notificationCenter.showAlert({
          title: 'Update error',
          status: 'error',
          message: 'Something went wrong. Please try again later.',
        });

        captureException(e);
      });
  };

  // Custom onChange handler that validates as you type
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handle(e);
    validateCompanyName(e.target.value);
  };

  return (
    <Card>
      <CardHeader title={'General'}/>

      <CardContent>
        <FormLayout>
          <FormItem
            title={t('common.company-name')}
          >
            <TextInput
              type="text"
              label="id"
              value={form.name}
              name="name"
              placeholder={t('common.company-name-placeholder')}
              onChange={handleNameChange}
            />
            {nameError && <p className="text-red-500 text-sm">{nameError}</p>}
          </FormItem>
          {auth?.role.includes('ADMIN') && (
            <FormItem title={'Category'} className="max-w-xl">
              <div className="grid-cols-2 grid gap-4">
                {Object.values(CompanyCategory).map((category) => (
                  <div key={category} className="sm:col-span-1 col-span-2">
                    <label className="mr-4"></label>
                    <input
                      className="orm-checkbox text-indigo-600 rounded"
                      type="checkbox"
                      value={category}
                      checked={form.category.includes(category)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setData({
                            ...form,
                            category: [...form.category, category],
                          });
                        } else {
                          setData({
                            ...form,
                            category: form.category.filter((c) => c !== category),
                          });
                        }
                      }}
                    />{' '}
                    <span className="text-sm">{category}</span>
                  </div>
                ))}
              </div>
            </FormItem>
          )}


          <FormItem title={'Logo'} className="max-w-md">
            <ImageInput
              client={client}
              images={form.logo ? [form.logo] : []}
              onChange={(newValue) => {
                if (newValue.length > 0) {
                  manualHandle('logo', newValue[0]);
                } else {
                  manualHandle('logo', null);
                }
              }}
            />
          </FormItem>

        </FormLayout>
      </CardContent>

      <CardFooter>
        <div className="flex justify-center space-x-4 w-32">
          <PrimaryButton
            id="create-button"
            data-testid="create-button"
            className="w-32 flex-1"
            onClick={() => {
              onUpdateHandler();
            }}
            disabled={!!nameError} // Disable button if there's an error
          >
            {t('common.update')}
          </PrimaryButton>
        </div>
      </CardFooter>
    </Card>
  );
}