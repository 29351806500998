import { BellIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import useGetUserNotification from '@client/page/management/user-notificartion/user-notification-list/logic/use-get-user-notifications.ts';
import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import moment from 'moment';
import { useLocalStorage } from 'usehooks-ts';
import useUpdateUserNotification from '@client/module/user-notification/logic/use-update-user-notification.ts';

const UserNotificationButton = () => {
  const navigate = useNavigate();
  const companyID = useCompanyId();

  const { data } = useGetUserNotification(companyID);

  const [newNotification, setNewNotification] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const [latestAccessTime, setLatestAccessTime] = useLocalStorage<string | undefined>('latestAccessTime', undefined, {
    serializer: (value) => value ?? moment().toISOString(),
    deserializer: (value) => value,
  });

  const [updateUserNotification] = useUpdateUserNotification();

  useEffect(() => {
    const notifications = data?.userNotifications ?? [];

    if (latestAccessTime) {
      // Filter notifications where pushedNotification is false
      const filteredNotifications = notifications.filter((notification) => !notification.data?.pushedNotification);

      if (filteredNotifications.at(0)) {
        const latestNotification = filteredNotifications.at(0);

        if (!latestNotification) {
          return;
        }

        if (moment(parseInt(latestNotification.createdAt)) > moment(latestAccessTime)) {
          setNewNotification(true);
        } else {
          setNewNotification(false);
        }
      }

      // Set notification count based on filtered notifications
      setNotificationCount(filteredNotifications.length);
    }
  }, [data, latestAccessTime, setNewNotification]);

  useEffect(() => {
    if (latestAccessTime === undefined) {
      setLatestAccessTime(moment().toISOString());
    }
  }, [latestAccessTime, setLatestAccessTime]);

  const handleButtonClick = async () => {
    navigate('/user-notification');
    setNewNotification(false);

    // Update all notifications to set pushedNotification to true
    const notifications = data?.userNotifications ?? [];
    for (const notification of notifications) {
      if (!notification.data?.pushedNotification) {
        await updateUserNotification({
          variables: {
            id: notification.id,
            input: {
              // title: notification.title,
              // message: notification.message,
              // link: notification.link,
              // companyId: companyID,
              data: {
                color: notification.data?.color,
                type: notification.data?.type,
                pushedNotification: true,
                name: notification.data?.name,
                time: notification.data?.time,
                price: notification.data?.price,
              },
            },
          },
        });
      }
    }
    // Navigate to user notification page after updating
  };

  return (
    <div>
      <button
        type="button"
        className=" p-2.5  rounded-full relative"
        /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
        onClick={handleButtonClick}
      >
        <div
          className={classNames({
            'h-5 w-5 bg-red-600 rounded-md absolute right-1 top-1 flex justify-center items-center': newNotification,
          })}
        >
          {newNotification && <span className="text-xs text-white ">{notificationCount}</span>}
        </div>
        <BellIcon className="h-8 w-8 text-indigo-500" aria-hidden="true" />
      </button>
    </div>
  );
};

export default UserNotificationButton;
